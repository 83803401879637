import React, { createContext, useState, useEffect } from 'react';
import { useMonaco } from '@monaco-editor/react';

const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {
    console.warn('toggleTheme function is not implemented');
  },
});

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('isDarkMode');
    return savedTheme ? JSON.parse(savedTheme) : false;
  });
  const monaco = useMonaco();

  const toggleTheme = () => {
    setIsDarkMode((prevMode: boolean) => {
      const newMode = !prevMode;
      localStorage.setItem('isDarkMode', JSON.stringify(newMode));
      return newMode;
    });
  };

  useEffect(() => {
    document.body.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  useEffect(() => {
    const disposable = monaco?.editor.onDidCreateEditor(() => {
      setTimeout(() =>
        monaco?.editor.setTheme(isDarkMode ? 'vs-dark' : 'light'),
      );
    });

    return () => disposable?.dispose();
  }, [monaco, isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
