import { Checkbox, Flex } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';

export const useOtherOptionsSettingsMapper = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Ignore total execStrategy from proforma invoices'),
      help: t(
        'If both trade invoices and proforma invoices are given, only total values from trade invoices are used and proforma values ignored.',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.ignoreTotalValueFromProformaInvoices,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            ignoreTotalValueFromProformaInvoices: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Email sender as representative email'),
      help: t('Sets the representative email to email sender.'),
      getValue: () =>
        execStrategy.dataNormalization?.emailSenderAsRepresentativeEmail,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            emailSenderAsRepresentativeEmail: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Allow date in order number'),
      help: t(
        'If you activate this, Digicust will allow dates in the order number. If you deactivate this, Digicust will remove them.',
      ),
      getValue: () => execStrategy.dataNormalization?.allowDateInOrderNumber,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            allowDateInOrderNumber: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Use specialized description aggregation'),
      help: t(
        'Enables experimental feature to use specialized description aggregation for line items, summing up quantities and concatenating lineItemDescriptions with "-" in the special "aggregated.aggregatedDescriptions" map (otherwise this property will remain empty or undefined)',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.useSpecializedDescriptionAggregation,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            useSpecializedDescriptionAggregation: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Remove stakeholder contact'),
      help: t(
        'If you activate this, Digicust will remove any stakeholder contact information (contact person, email, phone number) we find on documents.',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.removeStakeholderContactInformation
          ?.active,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            removeStakeholderContactInformation: {
              ...(execStrategy.dataNormalization
                ?.removeStakeholderContactInformation || {}),
              active: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Default license plate'),
      help: t(
        "Will set the license plate to default execStrategy, if not specified otherwise (e.g. 'FLUGZEUG' or 'LKW'.",
      ),
      getValue: () => !!execStrategy.dataNormalization?.defaultLicensePlate,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            defaultLicensePlate: checked ? 'german' : false,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Ignore line items from invoice'),
      help: t(
        'Digicust will ignore line items from invoice. This comes handy when information comes from other sources (e.g. Excel documents).',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.ignoreLineItemsFromInvoice,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            ignoreLineItemsFromInvoice: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Ignore line items from transit declaration'),
      help: t('Digicust will ignore line items from transit declarations.'),
      getValue: () =>
        execStrategy?.dataNormalization?.ignoreLineItemsFromTransitDeclaration,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            ignoreLineItemsFromTransitDeclaration: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Ignore line items without invoice'),
      help: t(
        'Digicust will ignore line items without invoice. This is for if line items come from transit documents and a new declaration is to be created for only one or few of the line items we actually have invoices for.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.ignoreLineItemsWithoutInvoice,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            ignoreLineItemsWithoutInvoice: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Use invoice number as LRN'),
      help: t('Digicust will use the invoice number as LRN.'),
      getValue: () => execStrategy?.dataNormalization?.forceInvoiceNumberToLRN,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            forceInvoiceNumberToLRN: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Use invoice number as reference number UCR'),
      help: t(
        'Digicust will use the invoice number as reference number UCR (AES3).',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.forceInvoiceNumberToReferenceNumberUCR,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            forceInvoiceNumberToReferenceNumberUCR: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Use invoice number as registration number external'),
      help: t(
        'Digicust will use the invoice number as registration number external (AES3).',
      ),
      getValue: () =>
        execStrategy?.dataNormalization
          ?.forceInvoiceNumberToRegistrationNumberExternal,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            forceInvoiceNumberToRegistrationNumberExternal: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Always assume 3 digit decimal for weights'),
      help: t(
        'Will explicitly assume 3 digit decimals for weights (even if unclear whether dot or comma is thousand separator or decimal separator.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization
          ?.alwaysAssumeThreeDigitDecimalForWeights,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            alwaysAssumeThreeDigitDecimalForWeights: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Always assume thousands for weight'),
      help: t(
        'Will explicitly assume thousands if > 3 digits in a weight measure.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.alwaysAssumeThousandsForWeights,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            alwaysAssumeThousandsForWeights: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Always assume thousands for quantity'),
      help: t(
        'Will explicitly assume thousands if > 3 digits in a quantity measure.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.alwaysAssumeThousandsForQuantity,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            alwaysAssumeThousandsForQuantity: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
  ];

  return { fields };
};

export default function OtherOptionsSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useOtherOptionsSettingsMapper({
    execStrategy,
    onValueChange,
  });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
