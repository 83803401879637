import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Space } from 'antd';

import { ExecutionStrategy } from 'digicust_types';
import { t } from 'i18next';
import { DCTooltip } from '../../../../common';
import DebouncedTextArea from '../../../../Components/Inputs/Common/DebouncedTextAirea';
import { DocumentTypeSelect } from '../../../../Components/Inputs/DocumentTypeSelect';
import CardWrapper from '../../Common/CardWrapper';

export default function ExtractionSettingsLLM({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  return (
    <CardWrapper>
      <Checkbox
        className="hoverable"
        checked={
          execStrategy?.dataExtraction?.llmOptions?.onlyConsiderFirstAndLastPage
        }
        onChange={(e) =>
          onValueChange({
            ...execStrategy,
            dataExtraction: {
              ...(execStrategy.dataExtraction || {}),
              llmOptions: {
                ...(execStrategy.dataExtraction?.llmOptions || {}),
                onlyConsiderFirstAndLastPage: e.target.checked,
              },
            },
          })
        }
      >
        {t('Only consider first and last page')}
        <DCTooltip
          text={t(
            'If activated, the engine will only consider the first and last page of the document for data extraction.',
          )}
        />
      </Checkbox>
      <div className="hoverable">
        {t('Classification Remarks')}
        <DCTooltip
          text={t(
            'Provide additional information in natural language on how documents shall be classified.',
          )}
        />
        <DebouncedTextArea
          size="small"
          value={
            execStrategy?.dataExtraction?.llmOptions?.classificationRemarks
          }
          onChange={(value) =>
            onValueChange({
              ...execStrategy,
              dataExtraction: {
                ...(execStrategy.dataExtraction || {}),
                llmOptions: {
                  ...(execStrategy.dataExtraction?.llmOptions || {}),
                  classificationRemarks: value,
                },
              },
            })
          }
        />
      </div>

      {execStrategy?.dataExtraction?.llmOptions?.extractionRemarks?.map(
        (remark, index) => (
          <CardWrapper key={index}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Space direction="horizontal">
                <DocumentTypeSelect
                  size="small"
                  value={remark.documentType || undefined}
                  onChange={(e) =>
                    onValueChange({
                      ...execStrategy,
                      dataExtraction: {
                        ...(execStrategy.dataExtraction || {}),
                        llmOptions: {
                          ...(execStrategy.dataExtraction?.llmOptions || {}),
                          extractionRemarks:
                            execStrategy?.dataExtraction?.llmOptions?.extractionRemarks?.map?.(
                              (r, i) =>
                                i === index ? { ...r, documentType: e } : r,
                            ),
                        },
                      },
                    })
                  }
                />
                <Button
                  type="link"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    onValueChange({
                      ...execStrategy,
                      dataExtraction: {
                        ...(execStrategy.dataExtraction || {}),
                        llmOptions: {
                          ...(execStrategy.dataExtraction?.llmOptions || {}),
                          extractionRemarks:
                            execStrategy?.dataExtraction?.llmOptions?.extractionRemarks?.filter(
                              (_, i) => i !== index,
                            ),
                        },
                      },
                    })
                  }
                ></Button>
              </Space>

              <Space direction="horizontal">
                {' '}
                <Checkbox
                  checked={remark.sendToFallbackEngine}
                  onChange={(e) =>
                    onValueChange({
                      ...execStrategy,
                      dataExtraction: {
                        ...(execStrategy.dataExtraction || {}),
                        llmOptions: {
                          ...(execStrategy.dataExtraction?.llmOptions || {}),
                          extractionRemarks:
                            execStrategy?.dataExtraction?.llmOptions?.extractionRemarks?.map?.(
                              (r, i) =>
                                i === index
                                  ? {
                                      ...r,
                                      sendToFallbackEngine: e.target.checked,
                                    }
                                  : r,
                            ),
                        },
                      },
                    })
                  }
                >
                  {t('Send to Fallback Engine')}
                </Checkbox>
                <Checkbox
                  checked={remark.ignore}
                  onChange={(e) =>
                    onValueChange({
                      ...execStrategy,
                      dataExtraction: {
                        ...(execStrategy.dataExtraction || {}),
                        llmOptions: {
                          ...(execStrategy.dataExtraction?.llmOptions || {}),
                          extractionRemarks:
                            execStrategy?.dataExtraction?.llmOptions?.extractionRemarks?.map?.(
                              (r, i) =>
                                i === index
                                  ? {
                                      ...r,
                                      ignore: e.target.checked,
                                    }
                                  : r,
                            ),
                        },
                      },
                    })
                  }
                >
                  {t('Ignore')}
                </Checkbox>
                <Checkbox
                  checked={remark.ignoreLineItems}
                  onChange={(e) =>
                    onValueChange({
                      ...execStrategy,
                      dataExtraction: {
                        ...(execStrategy.dataExtraction || {}),
                        llmOptions: {
                          ...(execStrategy.dataExtraction?.llmOptions || {}),
                          extractionRemarks:
                            execStrategy?.dataExtraction?.llmOptions?.extractionRemarks?.map?.(
                              (r, i) =>
                                i === index
                                  ? {
                                      ...r,
                                      ignoreLineItems: e.target.checked,
                                    }
                                  : r,
                            ),
                        },
                      },
                    })
                  }
                >
                  {t('Ignore Line Items')}
                </Checkbox>
              </Space>

              <div style={{ width: '100%' }}>
                {t('Remarks')}
                <DebouncedTextArea
                  style={{ width: '100%' }}
                  size="small"
                  value={remark.remarks}
                  onChange={(value) =>
                    onValueChange({
                      ...execStrategy,
                      dataExtraction: {
                        ...(execStrategy.dataExtraction || {}),
                        llmOptions: {
                          ...(execStrategy.dataExtraction?.llmOptions || {}),
                          extractionRemarks:
                            execStrategy?.dataExtraction?.llmOptions?.extractionRemarks?.map?.(
                              (r, i) =>
                                i === index
                                  ? {
                                      ...r,
                                      remarks: value,
                                    }
                                  : r,
                            ),
                        },
                      },
                    })
                  }
                />
              </div>
            </Space>
          </CardWrapper>
        ),
      )}
      <Button
        type="dashed"
        onClick={() =>
          onValueChange({
            ...execStrategy,
            dataExtraction: {
              ...(execStrategy.dataExtraction || {}),
              llmOptions: {
                ...(execStrategy.dataExtraction?.llmOptions || {}),
                extractionRemarks: [
                  ...(execStrategy?.dataExtraction?.llmOptions
                    ?.extractionRemarks || []),
                  {},
                ],
              },
            },
          })
        }
      >
        {t('Add extraction remarks')}
      </Button>
    </CardWrapper>
  );
}
