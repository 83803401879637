import { Input, InputRef, InputProps } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';

interface DebouncedInputProps {
  value?: string;
  onChange?: (e?: string) => void;
  placeholder?: string;
  id?: string;
  size?: 'large' | 'middle' | 'small';
  readOnly?: boolean;
  password?: boolean;
  suffix?: ReactNode;
  style?: React.CSSProperties;
}

const DebouncedInput = React.forwardRef<InputRef, DebouncedInputProps>(
  (
    {
      value,
      onChange,
      placeholder,
      id,
      suffix,
      style,
      size,
      readOnly,
      password,
    },
    ref,
  ) => {
    const [curValue, setCurValue] = useState<string>();

    useEffect(() => {
      if (value) setCurValue(value);
    }, [value]);

    const props: InputProps = {
      style: style,
      variant: 'filled',
      suffix: suffix,
      id: id,
      size: size,
      readOnly: readOnly,
      placeholder: placeholder,
      title: curValue,
      value: curValue,
      onChange: (e) => setCurValue(e.target.value),
      onBlur: () => onChange && onChange(curValue),
    };

    if (password) {
      return <Input.Password ref={ref} {...props} />;
    }

    return <Input ref={ref} {...props} />;
  },
);

export default DebouncedInput;
