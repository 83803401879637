import { CopyOutlined } from '@ant-design/icons';
import { CheckCard } from '@ant-design/pro-components';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  message,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import confirm from 'antd/es/modal/confirm';
import { ExecutionStrategy } from 'digicust_types';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../common';
import DebouncedInput from '../../../../Components/Inputs/Common/DebouncedInput';
import DebouncedTextArea from '../../../../Components/Inputs/Common/DebouncedTextAirea';
import { DocumentTypeSelect } from '../../../../Components/Inputs/DocumentTypeSelect';
import JSONataInput from '../../../../Components/Inputs/JSONataInput';
import { CaseFieldMapper } from '../../../CustomsCaseDetails/types';
import CardWrapper, { cardBorderCss } from '../../Common/CardWrapper';

export const useUploadServiceMapperES = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Default document type'),
      help: t(
        'Assume uploaded documents to be of this document type. If unsure, select Invoice and Digicust will classify automatically.',
      ),
      getValue: () => execStrategy?.defaultClassification,
      updateProperty: (defaultClassification) =>
        onValueChange({ ...execStrategy, defaultClassification }),
      renderComponent: (value, updateProperty) => {
        return (
          <DocumentTypeSelect
            size="small"
            value={value}
            onChange={(defaultClassification) =>
              updateProperty(defaultClassification)
            }
          />
        );
      },
    },
    {
      title: t('Default Procedure'),
      help: t(
        'Assume uploads in this strategy to be of this customs procedure.',
      ),
      getValue: () => execStrategy?.defaultProcedureMode,
      updateProperty: (defaultProcedureMode) =>
        onValueChange({ ...execStrategy, defaultProcedureMode }),
      renderComponent: (value, updateProperty) => {
        return (
          <Select
            popupMatchSelectWidth={false}
            size="small"
            showSearch
            placeholder={t('Procedure')}
            optionFilterProp="children"
            value={value}
            onChange={(defaultProcedureMode) =>
              updateProperty(defaultProcedureMode)
            }
          >
            <Select.Option value="export">{t('Export')}</Select.Option>
            <Select.Option value="import">{t('Import')}</Select.Option>
            <Select.Option value="intrastat">{t('Intrastat')}</Select.Option>
            <Select.Option value="transit">{t('Transit')}</Select.Option>
            <Select.Option value={undefined}>{t('None')}</Select.Option>
          </Select>
        );
      },
    },
    {
      title: t('Merge uploads with same reference'),
      help: t(
        'If multiple uploads with the same reference are uploaded, merge them into one customs case. If unchecked, Digicust will create a new customs case for each upload.',
      ),
      getValue: () => execStrategy?.mergeMultipleUploads,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          mergeMultipleUploads: checked,
        }),
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Handle XLSX uploads'),
      help: t('Allow Excel files to be uploaded.'),
      getValue: () =>
        execStrategy?.handleXLSXFileUploads === true ||
        execStrategy?.XLSXFileUploads?.active ||
        false,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          handleXLSXFileUploads: false,
          XLSXFileUploads: {
            ...(execStrategy?.XLSXFileUploads || {}),
            active: checked,
            strategy:
              execStrategy?.XLSXFileUploads?.strategy || 'asLineItemList',
          },
        }),
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <>
            <Checkbox
              className="hoverable"
              checked={value}
              onChange={(e) => updateProperty(e.target.checked)}
            >
              {title}
              {help && <DCTooltip text={help} />}
            </Checkbox>

            {execStrategy.XLSXFileUploads?.active && (
              <Card style={cardBorderCss}>
                <Form.Item label={t('Strategy')}>
                  <CheckCard.Group
                    onChange={(e: any) =>
                      onValueChange({
                        ...execStrategy,
                        handleXLSXFileUploads: false,
                        XLSXFileUploads: {
                          ...(execStrategy.XLSXFileUploads || {}),
                          strategy: e,
                        },
                      })
                    }
                    value={
                      execStrategy?.XLSXFileUploads?.strategy ||
                      'asLineItemList'
                    }
                  >
                    <CheckCard
                      title="As line item list"
                      description="Every row as new line item."
                      value="asLineItemList"
                    />
                    <CheckCard
                      title="As PDF"
                      description="Converts to PDF and extracts with AI"
                      value="asPDF"
                    />
                  </CheckCard.Group>
                </Form.Item>
                <Form.Item label="Sheetname">
                  <DebouncedInput
                    size="small"
                    placeholder="Sheet 1 (optional)"
                    value={execStrategy?.XLSXFileUploads?.sheetName || ''}
                    onChange={(value) => {
                      const strategy = value;
                      onValueChange({
                        ...execStrategy,
                        handleXLSXFileUploads: false,
                        XLSXFileUploads: {
                          ...(execStrategy.XLSXFileUploads || {}),
                          sheetName: strategy,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Card>
            )}
          </>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Invoice number as reference'),
      help: t(
        'Force the invoice number to be the reference of the customs case. If unchecked, Digicust will use the default reference (e.g. email subject).',
      ),
      getValue: () => execStrategy?.forceReference === 'invoiceNumber',
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          forceReference: checked ? 'invoiceNumber' : false,
          forceReferenceJSONata: '',
        }),
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Custom reference'),
      help: t(
        'Customize the reference of the customs case. If unchecked, Digicust will use the default reference (e.g. email subject).',
      ),
      getValue: () => !!execStrategy?.forceReferenceJSONata,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          forceReferenceJSONata: checked
            ? 'reference & " - " & $substring($now(), 0,10)'
            : '',
          forceReference: false,
        }),
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <>
            <Checkbox
              className="hoverable"
              checked={value}
              onChange={(e) => updateProperty(e.target.checked)}
            >
              {title}
              {help && <DCTooltip text={help} />}
            </Checkbox>

            {execStrategy.forceReferenceJSONata && (
              <CardWrapper>
                <Form layout="vertical">
                  <Form.Item
                    label={t('JSONata Format String')}
                    tooltip={t(
                      'The format string for the resulting case reference.',
                    )}
                  >
                    <JSONataInput
                      inputValue={execStrategy?.forceReferenceJSONata}
                      sampleInput={{
                        reference: 'Test Case',
                      }}
                      onChange={(e) =>
                        onValueChange({
                          ...execStrategy,
                          forceReferenceJSONata: e,
                        })
                      }
                    />
                  </Form.Item>
                </Form>
              </CardWrapper>
            )}
          </>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Reference in email'),
      help: t(
        'If reference is specified in the email body, Digicust will use it as case reference.',
      ),
      getValue: () => !!execStrategy?.referenceInEmail,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          referenceInEmail: checked,
        }),
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Embeddable upload form'),
      help: t(
        'A form your clients can fill out to create customs cases directly to your inbox. Can also be embedded to your website.',
      ),
      getValue: () => (execStrategy?.uploadWidgets?.length || 0) > 0,
      updateProperty: (checked) => {
        if (!checked) {
          confirm({
            title: t('Are you sure you want to remove the widget?'),
            onOk: () => onValueChange({ ...execStrategy, uploadWidgets: [] }),
            okText: t('Yes'),
            maskClosable: true,
            onCancel: () => {
              console.log('test');
            },
            cancelText: t('No'),
          });
        } else {
          onValueChange({
            ...execStrategy,
            uploadWidgets: [{ form: 'export', id: nanoid(20) }],
          });
        }
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <>
            <Checkbox
              className="hoverable"
              checked={value}
              onChange={(e) => updateProperty(e.target.checked)}
            >
              {title}
              {help && <DCTooltip text={help} />}
            </Checkbox>
            {execStrategy.uploadWidgets?.length && (
              <Card style={cardBorderCss}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Form">
                      <Select
                        popupMatchSelectWidth={false}
                        size="small"
                        showSearch
                        placeholder={t('Form')}
                        optionFilterProp="children"
                        value={execStrategy.uploadWidgets[0]?.form}
                        onChange={(e) =>
                          onValueChange({
                            ...execStrategy,
                            uploadWidgets: [
                              {
                                form: e,
                                ...(execStrategy?.uploadWidgets?.[0] || {}),
                              },
                            ],
                          })
                        }
                      >
                        <Select.Option value="export">
                          {t('Export')}
                        </Select.Option>
                        <Select.Option value="import">
                          {t('Import')}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Vendor name">
                      <DebouncedInput
                        size="small"
                        placeholder="Vendor name"
                        value={execStrategy?.uploadWidgets?.[0]?.vendor}
                        onChange={(e) => {
                          const newVendor = e;
                          onValueChange({
                            ...execStrategy,
                            uploadWidgets: [
                              {
                                ...(execStrategy?.uploadWidgets?.[0] || {}),
                                vendor: newVendor,
                              },
                              ...(execStrategy?.uploadWidgets?.slice?.(1) ||
                                []), // Keep any other items in the array unchanged
                            ],
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="Terms">
                      <DebouncedTextArea
                        size="small"
                        placeholder="Terms"
                        value={execStrategy?.uploadWidgets?.[0]?.terms || ''}
                        onChange={(value) => {
                          const newTerms = value;
                          onValueChange({
                            ...execStrategy,
                            uploadWidgets: [
                              {
                                ...(execStrategy?.uploadWidgets?.[0] || {}),
                                terms: newTerms,
                              },
                              ...(execStrategy?.uploadWidgets?.slice?.(1) ||
                                []), // Keep any other items in the array unchanged
                            ],
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          {t('IFrame')}{' '}
                          <DCTooltip
                            text={t(
                              'Embed this form to your website to allow your clients to create customs cases directly to Digicust.',
                            )}
                          />
                        </>
                      }
                    >
                      <DebouncedInput
                        size="small"
                        value={`<iframe src="https://with.digicust.com/${execStrategy?.uploadWidgets?.[0]?.form}/${execStrategy?.uploadWidgets?.[0]?.id}" width="600" height="400"></iframe>`}
                        readOnly
                        suffix={
                          <Button
                            icon={<CopyOutlined />}
                            type="text"
                            size="small"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `<iframe src="https://with.digicust.com/${execStrategy?.uploadWidgets?.[0]?.form}/${execStrategy?.uploadWidgets?.[0]?.id}" width="600" height="400"></iframe>`,
                              );
                              message.success('Copied');
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          {t('Form link')}{' '}
                          <DCTooltip
                            text={t(
                              'Share this link to allow your clients to create customs cases directly to Digicust.',
                            )}
                          />
                        </>
                      }
                    >
                      <DebouncedInput
                        size="small"
                        value={`https://with.digicust.com/${execStrategy?.uploadWidgets?.[0]?.form}/${execStrategy?.uploadWidgets?.[0]?.id}`}
                        readOnly
                        suffix={
                          <Button
                            icon={<CopyOutlined />}
                            type="text"
                            size="small"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                `https://with.digicust.com/${execStrategy?.uploadWidgets?.[0]?.form}/${execStrategy?.uploadWidgets?.[0]?.id}`,
                              )
                            }
                          />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Space direction="vertical">
                      <div
                        style={{
                          width: '350px',
                          height: '280px',
                          overflow: 'hidden',
                        }}
                      >
                        <iframe
                          src={`https://with.digicust.com/${execStrategy?.uploadWidgets?.[0]?.form}/${execStrategy?.uploadWidgets?.[0]?.id}`}
                          width="350"
                          height="280"
                          style={{
                            border: 'none',
                            transform: 'scale(0.5)', // Adjust the scale as needed
                            transformOrigin: '0 0', // Ensures the iframe content is scaled from the top left corner
                            width: '700px', // Adjust the width to compensate for the scaling
                            height: '500px', // Adjust the height to compensate for the scaling
                          }}
                        ></iframe>
                      </div>
                    </Space>
                  </Col>
                </Row>
              </Card>
            )}
          </>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Upload from SFTP'),
      help: t(
        'Upload customs cases to Digicust by placing PDF documents in a specific folder on an SFTP server. Digicust will periodically check for new documents and create customs cases automatically.',
      ),
      getValue: () => !!execStrategy?.importViaSFTP?.active,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          importViaSFTP: {
            ...(execStrategy.importViaSFTP || {}),
            active: checked,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <>
            <Checkbox
              className="hoverable"
              checked={value}
              onChange={(e) => updateProperty(e.target.checked)}
            >
              {title}
              {help && <DCTooltip text={help} />}
            </Checkbox>
            {execStrategy.importViaSFTP?.active && (
              <Card style={cardBorderCss}>
                <Form.Item label={t('DNS Name')} style={{ marginBottom: 5 }}>
                  <DebouncedInput
                    size="small"
                    placeholder={t('DNS Name')}
                    value={execStrategy?.importViaSFTP?.['sftp-dns-name']}
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        importViaSFTP: {
                          ...(execStrategy.importViaSFTP || {}),
                          'sftp-dns-name': e,
                        },
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Port')} style={{ marginBottom: 5 }}>
                  <DebouncedInput
                    size="small"
                    placeholder={t('Port')}
                    value={execStrategy?.importViaSFTP?.['sftp-port']}
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        importViaSFTP: {
                          ...(execStrategy.importViaSFTP || {}),
                          'sftp-port': e,
                        },
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Login Name')} style={{ marginBottom: 5 }}>
                  <DebouncedInput
                    size="small"
                    placeholder={t('Login Name')}
                    value={execStrategy?.importViaSFTP?.['sftp-login-name']}
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        importViaSFTP: {
                          ...(execStrategy.importViaSFTP || {}),
                          'sftp-login-name': e,
                        },
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label={t('Password')} style={{ marginBottom: 5 }}>
                  <DebouncedInput
                    size="small"
                    password
                    placeholder={t('Password')}
                    value={execStrategy?.importViaSFTP?.['sftp-password']}
                    onChange={(value) =>
                      onValueChange({
                        ...execStrategy,
                        importViaSFTP: {
                          ...(execStrategy.importViaSFTP || {}),
                          'sftp-password': value,
                        },
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={t('Inbound Path')}
                  style={{ marginBottom: 5 }}
                >
                  <DebouncedInput
                    size="small"
                    placeholder={t('Inbound Path')}
                    value={execStrategy?.importViaSFTP?.['sftp-inbound']}
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        importViaSFTP: {
                          ...(execStrategy.importViaSFTP || {}),
                          'sftp-inbound': e,
                        },
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={t('Outbound Path')}
                  style={{ marginBottom: 5 }}
                >
                  <DebouncedInput
                    size="small"
                    placeholder={t('Outbound Path')}
                    value={execStrategy?.importViaSFTP?.['sftp-outbound']}
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        importViaSFTP: {
                          ...(execStrategy.importViaSFTP || {}),
                          'sftp-outbound': e,
                        },
                      })
                    }
                  />
                </Form.Item>
              </Card>
            )}
          </>
        );
      },
      isCheckbox: true,
    },
  ];

  return { fields };
};

export const UploadCard = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const { fields } = useUploadServiceMapperES({
    execStrategy,
    onValueChange,
  });

  return (
    <CardWrapper
      title={t('Upload')}
      description={t('Specify how cases are being uploaded to Digicust')}
      alternative
    >
      <Alert
        message={
          execStrategy?.whitelistedImportEmails?.find((email) =>
            email.includes('@with.digicust.com'),
          ) ? (
            <Typography.Text>
              {t('Send emails to')}{' '}
              {execStrategy?.whitelistedImportEmails
                ?.filter((email) => email?.includes('@with.digicust.com'))
                ?.map((email) => (
                  <Typography.Text strong copyable>
                    {email}
                  </Typography.Text>
                ))}{' '}
              {t(
                'to upload customs cases to this execution strategy. Simply attach PDF documents.',
              )}{' '}
              <Typography.Link
                onClick={() =>
                  onValueChange({
                    ...execStrategy,
                    whitelistedImportEmails: [],
                  })
                }
              >
                {t('Remove email')}
              </Typography.Link>
            </Typography.Text>
          ) : (
            t(
              'Save execution strategy to get a unique email address for this execution strategy to which you can send customs cases to.',
            )
          )
        }
        type="info"
        style={{ marginBottom: '10px' }}
      />

      <Flex wrap gap={6}>
        {fields.map((field) => {
          if (field.isCheckbox) {
            return field.renderComponent(
              field.getValue(),
              field.updateProperty,
              field.title,
              field.help as string,
            );
          }

          return (
            <div className="hoverable" style={{ display: 'inline-block' }}>
              {field.title}
              <DCTooltip text={field.help as string} />
              {field.renderComponent(field.getValue(), field.updateProperty)}
            </div>
          );
        })}
      </Flex>
    </CardWrapper>
  );
};
