import {
  UilAnnoyedAlt,
  UilBedDouble,
  UilBrushAlt,
  UilCircleLayer,
  UilFootball,
  UilGlass,
  UilMetro,
  UilMusic,
  UilPizzaSlice,
  UilPlane,
  UilPrescriptionBottle,
  UilRobot,
  UilShip,
  UilSperms,
  UilTrees,
  UilTruck,
  UilUmbrella,
  UilWatch,
  UilWeight,
} from '@iconscout/react-unicons';
import { Tooltip } from 'antd';

const TariffNumberIcon = (props: { tariffNumber: string }) => {
  const iconProperties = { color: 'grey', size: 14 };
  const options = [
    {
      section: 'Section 1: Live animals and animal products',
      startsWith: ['01', '02', '03', '04', '05'],
      icon: <UilSperms {...iconProperties} />,
    },
    {
      section: 'Section 2: Vegetable products',

      startsWith: ['06', '07', '08', '09', '10', '11', '12', '13', '14'],
      icon: <UilTrees {...iconProperties} />,
    },
    {
      section:
        'Section 3: Animal, vegetable or microbial fats and oils and their cleavage products; prepared edible fats; animal or vegetable waxes',
      startsWith: ['15'],
      icon: <UilPrescriptionBottle {...iconProperties} />,
    },
    {
      section:
        'Section 4: Prepared foodstuffs; beverages, spirits and vinegar; tobacco and manufactured tobacco substitutes; products, whether or not containing nicotine, intended for inhalation without combustion; other nicotine containing products intended for the intake of nicotine into the human body',
      startsWith: ['16', '17', '18', '19', '20', '21', '22', '23', '24'],
      icon: <UilPizzaSlice {...iconProperties} />,
    },
    {
      section: 'Section 5: Mineral products',
      startsWith: ['25', '26', '27'],
      icon: <UilCircleLayer {...iconProperties} />,
    },
    {
      section: 'Section 6: Products of the chemical or allied industries',
      startsWith: [
        '28',
        '29',
        '30',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38',
      ],
      icon: <UilCircleLayer {...iconProperties} />,
    },
    {
      section:
        'Section 7: Plastics and articles thereof; rubber and articles thereof',

      startsWith: ['39', '40'],
      icon: <UilCircleLayer {...iconProperties} />,
    },
    {
      section:
        'Section 8: raw hides and skins, leather, furskins and articles thereof; saddlery and harness; travel goods, handbags and similar containers; articles of animal gut (other than silkworm gut)',

      startsWith: ['41', '42', '43'],
      icon: <UilCircleLayer {...iconProperties} />,
    },
    {
      section:
        'Section 9: Wood and articles of wood; wood charcoal; cork and articles of cork; manufactures of straw, of esparto or of other plaiting materials; basketware and wickerwork',

      startsWith: ['44', '45', '46'],

      icon: <UilTrees {...iconProperties} />,
    },
    {
      section:
        'Section 10: Pulp of wood or of other fibrous cellulosic material; recovered (waste and scrap) paper or paperboard; paper and paperboard and articles thereof',
      startsWith: ['47', '48', '49'],
      icon: <UilTrees {...iconProperties} />,
    },
    {
      section: 'Section 11: Textiles and textile articles',
      startsWith: [
        '50',
        '51',
        '52',
        '53',
        '54',
        '55',
        '56',
        '57',
        '58',
        '59',
        '60',
        '61',
        '62',
        '63',
      ],
      icon: <UilCircleLayer {...iconProperties} />,
    },
    {
      section:
        'Section 12: Footwear, headgear, umbrellas, sun umbrellas, walking sticks, seat-sticks, whips, riding-crops and parts thereof; prepared feathers and articles made therewith; artificial flowers; articles of human hair',
      startsWith: ['64', '65', '66', '67'],
      icon: <UilUmbrella {...iconProperties} />,
    },
    {
      section:
        'Section 13: Articles of stone, plaster, cement, asbestos, mica or similar materials; ceramic products; glass and glassware',
      startsWith: ['68', '69', '70'],
      icon: <UilGlass {...iconProperties} />,
    },
    {
      section:
        'Section 14: Natural or cultured pearls, precious or semi-precious stones, precious metals, metals clad with precious metal, and articles thereof; imitation jewellery; coin',
      startsWith: ['71'],
      icon: <UilCircleLayer {...iconProperties} />,
    },
    {
      section: 'Section 15: Base metals and articles of base metal',
      startsWith: [
        '72',
        '73',
        '74',
        '75',
        '76',
        '77',
        '78',
        '79',
        '80',
        '81',
        '82',
        '83',
      ],
      icon: <UilCircleLayer {...iconProperties} />,
    },
    {
      section:
        'Section 16: Machinery and mechanical appliances; electrical equipment; parts thereof; sound recorders and reproducers, television image and sound recorders and reproducers, and parts and accessories of such articles',
      startsWith: ['84', '85'],
      icon: <UilRobot {...iconProperties} />,
    },
    {
      section:
        'Section 17: Vehicles, aircraft, vessels and associated transport equipment',
      startsWith: ['86'],
      icon: <UilMetro {...iconProperties} />,
    },
    {
      section:
        'Section 17: Vehicles, aircraft, vessels and associated transport equipment',

      startsWith: ['87'],
      icon: <UilTruck {...iconProperties} />,
    },
    {
      section:
        'Section 17: Vehicles, aircraft, vessels and associated transport equipment',

      startsWith: ['88'],
      icon: <UilPlane {...iconProperties} />,
    },
    {
      section:
        'Section 17: Vehicles, aircraft, vessels and associated transport equipment',

      startsWith: ['89'],
      icon: <UilShip {...iconProperties} />,
    },
    {
      section:
        'Section 18: Optical, photographic, cinematographic, measuring, checking, precision, medical or surgical instruments and apparatus; clocks and watches; musical instruments; parts and accessories thereof',
      startsWith: ['90'],
      icon: <UilWeight {...iconProperties} />,
    },
    {
      section:
        'Section 18: Optical, photographic, cinematographic, measuring, checking, precision, medical or surgical instruments and apparatus; clocks and watches; musical instruments; parts and accessories thereof',
      startsWith: ['91'],
      icon: <UilWatch {...iconProperties} />,
    },
    {
      section:
        'Section 18: Optical, photographic, cinematographic, measuring, checking, precision, medical or surgical instruments and apparatus; clocks and watches; musical instruments; parts and accessories thereof',
      startsWith: ['92'],
      icon: <UilMusic {...iconProperties} />,
    },
    {
      section: 'Section 19: Arms and ammunition; parts and accessories thereof',

      startsWith: ['93'],
      icon: <UilAnnoyedAlt {...iconProperties} />,
    },
    {
      section: 'Section 20: Miscellaneous manufactured articles',
      startsWith: ['94'],
      icon: <UilBedDouble {...iconProperties} />,
    },
    {
      section: 'Section 20: Miscellaneous manufactured articles',
      startsWith: ['95'],
      icon: <UilFootball {...iconProperties} />,
    },
    {
      section: 'Section 20: Miscellaneous manufactured articles',
      startsWith: ['96'],
      icon: <UilCircleLayer {...iconProperties} />,
    },
    {
      section: "Section 21: Works of art, collectors' pieces and antiques",
      startsWith: ['97'],
      icon: <UilBrushAlt {...iconProperties} />,
    },
    {
      section: "Section 21: Works of art, collectors' pieces and antiques",
      startsWith: ['98'],
      icon: <UilCircleLayer {...iconProperties} />,
    },
  ];
  if (props.tariffNumber) {
    const icon = options.find((option) =>
      option.startsWith?.some((val) => props.tariffNumber.startsWith(val)),
    );
    return icon ? <Tooltip title={icon?.section}>{icon?.icon}</Tooltip> : null;
  }
  return null;
};

export default TariffNumberIcon;
