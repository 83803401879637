import { Avatar, Typography } from 'antd';
import CustomsSystemSelection from './CustomsSystemSelection';

import {
  AebSftpConfig,
  AsycudaSftpConfig,
  BEOConfig,
  CargoSoftSftpConfig,
  DakosySftpConfig,
  DbhSftpConfig,
  DhfSftpConfig,
  ExecutionStrategy,
  FormatSftpConfig,
  LdvSftpConfig,
  MercurioSftpConfig,
  ScopeSftpConfig,
  SubmissionVendor,
} from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { getCustomsSoftwareLogoFromString } from '../../../../utils/helpers/getCustomsSoftwareLogoFromString';
import AEBSystemForm from './AEBSystemForm';
import AsycudaSystemForm from './AsycudaSystemForm';
import BEOSystemForm from './BEOSystemForm';
import CargoSoftSystemForm from './CargoSoftSystemForm';
import DakosySystemForm from './DakosySystemForm';
import DBHSystemForm from './DBHSystemForm';
import DHFSystemForm from './DHFSystemForm';
import FormatSystemForm from './FormatSystemForm';
import LDVSystemForm from './LDVSystemForm';
import MercurioSystemForm from './MercurioSystemForm';
import ScopeSystemForm from './ScopeSystemForm';

export default function SubmissionsTab({
  data,
  onValueChange,
}: {
  data: ExecutionStrategy;
  onValueChange: (value: ExecutionStrategy) => void;
}) {
  const { t } = useTranslation();

  const changeData = (key: string, value: string | boolean) => {
    onValueChange({
      ...data,
      submission: {
        ...(data.submission || { vendor: SubmissionVendor.none }),
        config: {
          ...(data.submission?.config || {}),
          [key]: value,
        },
      },
    });
  };

  return (
    <div
      style={{
        padding: '20px 16px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 200px)',
      }}
    >
      <h3 style={{ marginBottom: '10px' }}>
        {t('Integrate with your customs system.')}
      </h3>

      <CustomsSystemSelection
        value={data?.submission?.vendor}
        onChange={(value) => {
          onValueChange({
            ...data,
            submission: {
              ...(data?.submission || {}),
              vendor: value as SubmissionVendor,
              active: value !== 'none',
              config: data?.submission?.config || {},
            },
          });
        }}
      />

      {(data?.submission?.vendor || 'none') !== 'none' && (
        <div
          style={{
            margin: '20px 0',
            display: 'flex',
            gap: '10px',
          }}
        >
          <Avatar
            shape="square"
            size="large"
            style={{ width: 'auto' }}
            src={getCustomsSoftwareLogoFromString(data?.submission?.vendor)}
          />

          <Typography.Paragraph>
            <strong>
              {t('Set up your')} {data.submission?.vendor.toUpperCase()}{' '}
              {t('account')}.
            </strong>
            <br />
            {t(
              'Digicust processes your customs cases and sends the data to your',
            )}{' '}
            {data.submission?.vendor.toUpperCase()} {t('system')}.
          </Typography.Paragraph>
        </div>
      )}

      <div style={{ maxWidth: '800px' }}>
        {data?.submission?.vendor === SubmissionVendor.dakosy && (
          <DakosySystemForm
            config={data?.submission?.config as DakosySftpConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.aeb && (
          <AEBSystemForm
            config={data?.submission?.config as AebSftpConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.ldv && (
          <LDVSystemForm
            config={data?.submission?.config as LdvSftpConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.dhf && (
          <DHFSystemForm
            config={data?.submission?.config as DhfSftpConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.asycuda && (
          <AsycudaSystemForm
            config={data?.submission?.config as AsycudaSftpConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.dbh && (
          <DBHSystemForm
            config={data?.submission?.config as DbhSftpConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.format && (
          <FormatSystemForm
            config={data?.submission?.config as FormatSftpConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.beo && (
          <BEOSystemForm
            config={data?.submission?.config as BEOConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.cargosoft && (
          <CargoSoftSystemForm
            config={data?.submission?.config as CargoSoftSftpConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.mercurio && (
          <MercurioSystemForm
            config={data?.submission?.config as MercurioSftpConfig}
            changeData={changeData}
          />
        )}
        {data?.submission?.vendor === SubmissionVendor.scope && (
          <ScopeSystemForm
            config={data?.submission?.config as ScopeSftpConfig}
            changeData={changeData}
          />
        )}
      </div>
    </div>
  );
}
