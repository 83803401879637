import { Input, InputRef } from 'antd';
import { Ref, useEffect, useState } from 'react';

const NumberInput = ({
  value,
  onChange,
  placeholder,
  ref,
  id,
  size,
  style,
  min = 0,
  max,
  step = 1,
  suffix,
}: {
  value?: number;
  placeholder?: string;
  onChange?: (e?: number) => void;
  ref?: Ref<InputRef>;
  id?: string;
  size?: 'large' | 'middle' | 'small';
  style?: React.CSSProperties;
  min?: number;
  max?: number;
  step?: number;
  suffix?: string;
}) => {
  const [curValue, setCurValue] = useState<number>();
  useEffect(() => setCurValue(value), [value]);

  return (
    <Input
      variant="filled"
      ref={ref}
      id={id}
      type="number"
      style={style}
      max={max}
      min={min}
      step={step}
      size={size}
      suffix={suffix}
      placeholder={placeholder}
      title={curValue?.toString()}
      value={curValue}
      onChange={(e) => setCurValue(Number(e.target.value))}
      onBlur={() => onChange && onChange(curValue)}
    />
  );
};

export default NumberInput;
