import { Card, Checkbox, Flex } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import PackageTypeSelect from '../../../../../Components/Inputs/PackageTypeSelect';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import { cardBorderCss } from '../../../Common/CardWrapper';

export const usePackagesMapperES = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Prefer packages from head'),
      help: t(
        'Prefers packages from invoice head over packages from line items.',
      ),
      getValue: () => execStrategy?.dataNormalization?.preferPackagesFromHead,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            preferPackagesFromHead: checked,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Enforce package type'),
      help: t(
        'In case you want to enforce a specific package type, you can do so here. E.g. if you want to enforce that all packages are pallets, you can do so here.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.enforcePackageType !== undefined,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            enforcePackageType: checked
              ? {
                  type: 'Package',
                  germanType: 'Packung/Packstück',
                  code: 'PK',
                  NC_40000: 'PK',
                }
              : undefined,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <>
            <Checkbox
              className="hoverable"
              checked={value}
              onChange={(e) => updateProperty(e.target.checked)}
            >
              {title}
              {help && <DCTooltip text={help} />}
            </Checkbox>

            {execStrategy?.dataNormalization?.enforcePackageType !==
              undefined && (
              <Card style={cardBorderCss}>
                <PackageTypeSelect
                  value={execStrategy.dataNormalization?.enforcePackageType}
                  onChange={(e) =>
                    onValueChange({
                      ...execStrategy,
                      dataNormalization: {
                        ...(execStrategy.dataNormalization || {}),
                        enforcePackageType: e || undefined,
                      },
                    })
                  }
                />
              </Card>
            )}
          </>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Use item quantity as package count'),
      help: t(
        'Often, the quantity of items is the same as the number of packages. Activate, if you want to use the item quantity as package count. E.g. 50 pieces = 50 packages.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.useQuantityAsPackageCount,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            useQuantityAsPackageCount: checked,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
      isCheckbox: true,
    },
  ];

  return { fields };
};

export default function PackagesSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = usePackagesMapperES({
    execStrategy,
    onValueChange,
  });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
