import React, { useState, useEffect } from 'react';
import {
  Button,
  DatePicker,
  Spin,
  Table,
  Typography,
  Space,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setStatisticsData } from '../../Components/reduxToolkit/statisticsSlice';
import { RootState } from '../../Components/reduxToolkit/store';
import { PageLayout } from '../../common';
import { CaseRepository } from '../../utils/repositories/case.repository';
import { StatisticsFilters } from '../Statistics/types';
import dayjs from 'dayjs';
import { FileExcelOutlined, FileTextOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

const CBAM: React.FC = () => {
  const { customerId, projectId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const statistics = useSelector(
    (state: RootState) => state?.statisticsSlice?.data,
  );
  const [activeKey, setKey] = useState<string>('cases');
  const [filters, setFilters] = useState<StatisticsFilters>({});
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [pivotState, setPivotState] = useState<any>({});

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await CaseRepository.getCBAMData(
        customerId,
        projectId,
        filters,
      );
      setData(response);
    } catch (error) {
      notification.error({
        message: t('Error'),
        description: t('Failed to load data. Please try again later.'),
      });
    }
    setLoading(false);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'CBAM Data');
    XLSX.writeFile(wb, 'CBAM_Data.xlsx');
  };

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'CBAM_Data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <PageLayout title={t('CBAM')} subTitle={t('Report carbon emissions')}>
      <div
        style={{
          marginLeft: '10px',
          marginRight: '10px',
          marginBottom: '20px',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        {!data && !loading && (
          <div>
            <Typography.Text>
              {t(
                'Loading the pivot data might take several minutes. Filter time range to reduce the loading time.',
              )}
            </Typography.Text>
            <br />
            <Space direction="horizontal">
              <DatePicker.RangePicker
                style={{ width: '100%', maxWidth: '300px', marginTop: '10px' }}
                allowClear
                defaultValue={
                  filters?.startTime && filters?.endTime
                    ? [dayjs(filters.startTime), dayjs(filters.endTime)]
                    : undefined
                }
                onChange={(_, dateString) => {
                  if (!dateString[0]) setFilters({});
                  else
                    setFilters({
                      startTime: dayjs(dateString[0])
                        .startOf('day')
                        .toISOString(),
                      endTime: dayjs(dateString[1]).endOf('day').toISOString(),
                    });
                }}
              />
              <Button
                type="primary"
                onClick={loadData}
                style={{ marginTop: '10px' }}
              >
                {t('Load Data')}
              </Button>
            </Space>
          </div>
        )}{' '}
        {loading ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin size="large" />
          </div>
        ) : (
          data && (
            <>
              <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                <Space>
                  <Button icon={<FileExcelOutlined />} onClick={exportToExcel}>
                    {t('Export to Excel')}
                  </Button>
                  <Button icon={<FileTextOutlined />} onClick={exportToCSV}>
                    {t('Export to CSV')}
                  </Button>
                </Space>
              </div>
              <Table
                scroll={{ x: true, y: 450 }}
                dataSource={data}
                columns={[
                  { title: 'Case ID', dataIndex: 'caseId', key: 'caseId' },
                  {
                    title: 'Reference',
                    dataIndex: 'reference',
                    key: 'reference',
                  },
                  {
                    title: 'Declarant Name',
                    dataIndex: 'declarantName',
                    key: 'declarantName',
                  },
                  {
                    title: 'Shipper Name',
                    dataIndex: 'shipperName',
                    key: 'shipperName',
                  },
                  {
                    title: 'Shipper Locality',
                    dataIndex: 'shipperLocality',
                    key: 'shipperLocality',
                  },
                  {
                    title: 'Shipper Street Name',
                    dataIndex: 'shipperStreetName',
                    key: 'shipperStreetName',
                  },
                  {
                    title: 'Shipper Street Number',
                    dataIndex: 'shipperStreetNumber',
                    key: 'shipperStreetNumber',
                  },
                  {
                    title: 'Shipper Postal Code',
                    dataIndex: 'shipperPostalCode',
                    key: 'shipperPostalCode',
                  },
                  {
                    title: 'Shipper Country',
                    dataIndex: 'shipperCountry',
                    key: 'shipperCountry',
                  },
                  {
                    title: 'Shipper Is EU',
                    dataIndex: 'shipperIsEU',
                    key: 'shipperIsEU',
                  },
                  {
                    title: 'Consignee Name',
                    dataIndex: 'consigneeName',
                    key: 'consigneeName',
                  },
                  { title: 'Item ID', dataIndex: 'id', key: 'id' },
                  {
                    title: 'Created At',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                  },
                  {
                    title: 'Direct Emissions',
                    dataIndex: 'directEmissions',
                    key: 'directEmissions',
                  },
                  {
                    title: 'Indirect Emissions',
                    dataIndex: 'indirectEmissions',
                    key: 'indirectEmissions',
                  },
                ]}
                pagination={{ pageSize: 10 }}
                bordered
                style={{ borderRadius: '8px' }}
              />
            </>
          )
        )}
      </div>
    </PageLayout>
  );
};

export default CBAM;
