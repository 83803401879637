import {
  Card,
  Checkbox,
  Flex,
  Form,
  Radio,
  Select,
  Space,
  Typography,
} from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { WiStars } from 'react-icons/wi';
import { DCTooltip } from '../../../../../common';
import DebouncedInput from '../../../../../Components/Inputs/Common/DebouncedInput';
import JSONataInput from '../../../../../Components/Inputs/JSONataInput';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import { cardBorderCss } from '../../../Common/CardWrapper';
import { languages } from './languages';

export const useGoodsDescriptionSettingsMapper = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Translate item descriptions'),
      help: t(
        'Digicust can translate item descriptions. If you activate this, Digicust will translate item descriptions automatically.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.translateLineItemDescriptions?.active,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            translateLineItemDescriptions: {
              ...(execStrategy?.dataNormalization
                ?.translateLineItemDescriptions || {}),
              active: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <>
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            <WiStars
              color="rgb(22, 119, 255)"
              size={26}
              style={{ marginTop: -20, marginBottom: -8 }}
            />
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
          {value && (
            <Card style={cardBorderCss}>
              <Form layout="vertical">
                <Form.Item
                  label={t('Target language')}
                  tooltip={t(
                    'The language in which to translate the item descriptions.',
                  )}
                >
                  <Select
                    popupMatchSelectWidth={false}
                    placeholder={t('Target language')}
                    optionFilterProp="children"
                    value={
                      execStrategy?.dataNormalization
                        ?.translateLineItemDescriptions?.destinationLanguage
                    }
                    showSearch
                    onChange={(lang) => updateProperty(lang)}
                  >
                    {languages.map((lang) => (
                      <Select.Option key={lang.code} value={lang.code}>
                        {lang.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Card>
          )}
        </>
      ),
      isCheckbox: true,
    },
    {
      title: t('Search for item description on the web'),
      help: t('Will try to find additional product information on Google.'),
      getValue: () =>
        execStrategy?.dataNormalization?.searchForItemDescriptionOnInternet
          ?.active,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            searchForItemDescriptionOnInternet: {
              ...(execStrategy?.dataNormalization
                ?.searchForItemDescriptionOnInternet || {}),
              active: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          <WiStars
            color="rgb(22, 119, 255)"
            size={26}
            style={{ marginTop: -20, marginBottom: -8 }}
          />
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Custom goods description'),
      help: t('Custom formatting for the resulting description of line items.'),
      getValue: () =>
        (execStrategy?.dataNormalization?.descriptionFormatString?.length ||
          execStrategy?.dataNormalization?.descriptionFormatStringJsonAta
            ?.length ||
          0) > 0,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            descriptionFormatString: checked ? '{line-item-description}' : '',
            descriptionFormatStringJsonAta: '',
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <>
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
          {value && (
            <Card style={cardBorderCss}>
              <Radio.Group
                value={
                  execStrategy.dataNormalization?.descriptionFormatStringJsonAta
                    ?.length
                    ? 'b'
                    : 'a'
                }
                onChange={(e) => {
                  if (e.target.value === 'a') {
                    onValueChange({
                      ...execStrategy,
                      dataNormalization: {
                        ...(execStrategy.dataNormalization || {}),
                        descriptionFormatString:
                          execStrategy.dataNormalization
                            ?.descriptionFormatString ||
                          '{line-item-description}',
                        descriptionFormatStringJsonAta: '',
                      },
                    });
                  } else {
                    onValueChange({
                      ...execStrategy,
                      dataNormalization: {
                        ...(execStrategy.dataNormalization || {}),
                        descriptionFormatString: '',
                        descriptionFormatStringJsonAta:
                          execStrategy.dataNormalization
                            ?.descriptionFormatStringJsonAta ||
                          `default_jsonata_string`,
                      },
                    });
                  }
                }}
                size="small"
              >
                <Radio.Button value="a">{t('Simple')}</Radio.Button>
                <Radio.Button value="b">{t('Advanced (JSONata)')}</Radio.Button>
              </Radio.Group>
              {execStrategy.dataNormalization
                ?.descriptionFormatStringJsonAta ? (
                <Form layout="vertical">
                  <Form.Item
                    label={t('JSONata Format String')}
                    tooltip={t(
                      'The format string for the resulting description of line items.',
                    )}
                  >
                    <JSONataInput
                      inputValue={
                        execStrategy?.dataNormalization
                          ?.descriptionFormatStringJsonAta
                      }
                      onChange={(val) =>
                        onValueChange({
                          ...execStrategy,
                          dataNormalization: {
                            ...(execStrategy.dataNormalization || {}),
                            descriptionFormatStringJsonAta: val,
                          },
                        })
                      }
                    />
                  </Form.Item>
                  <Card>
                    <Space direction="vertical">
                      <Typography.Title level={4} style={{ marginTop: 0 }}>
                        {t('JSONata Formatting Options')}
                      </Typography.Title>
                      <Typography.Text>
                        {t(
                          'Custom formattings for the resulting description of line items as a JSONAta query.',
                        )}
                      </Typography.Text>
                      <Typography.Text strong>
                        {t(
                          'The input JSON object is expected to have the following structure:',
                        )}
                      </Typography.Text>
                      <Typography.Text code>
                        {JSON.stringify({
                          tariffNumberDescription:
                            'Einrichtungen, Maschinen, Apparate und Geräte zur Kälteerzeugung',
                          lineItemDescription:
                            'Orsiro Mission 2.5/9 PTCA-Stents ',
                          lineItemDescriptionShort:
                            'Orsiro Mission PTCA-Stents ',
                          materialDescription: 'PCTA-Stents',
                          enrichedDescription: 'PCTA-Stents',
                          enrichedDescriptionShort: 'Stents',
                          preferMaterialMasterData: false,
                          materialMatched: true,
                          useCustomsTariffNumberDescription: true,
                          useLineItemDescriptionShortening: true,
                          materialMatchedByTariffNumber: true,
                          materialMatchedByMaterialNr: false,
                        })}
                      </Typography.Text>
                    </Space>
                  </Card>
                </Form>
              ) : (
                <Form layout="vertical">
                  <Form.Item
                    label={t('Format String')}
                    tooltip={t(
                      'The format string for the resulting description of line items.',
                    )}
                  >
                    <DebouncedInput
                      value={
                        execStrategy?.dataNormalization?.descriptionFormatString
                      }
                      onChange={(value) =>
                        onValueChange({
                          ...execStrategy,
                          dataNormalization: {
                            ...(execStrategy.dataNormalization || {}),
                            descriptionFormatString: value,
                          },
                        })
                      }
                    />
                  </Form.Item>

                  <Card>
                    <Space direction="vertical">
                      <Typography.Title level={4} style={{ marginTop: 0 }}>
                        {t('Custom Formatting Options')}
                      </Typography.Title>
                      <Typography.Text>
                        {t(
                          'Customize the presentation of your line items with the following variables. Combine them with optional separators for a tailored display.',
                        )}
                      </Typography.Text>
                      <dl>
                        <dt>
                          <code>{'{line-item-description}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'The standard description extracted from your document. This field is always populated.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{line-item-description-short}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'A shortened version of the line item description. Applied only if the original contains more than four words.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{material-description}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'The description from the matched material in your master data. This is based on material number or tariff number. If no match is found, or if matching is disabled, this will remain empty.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{enriched-description}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'Displays the material description if available; otherwise, it defaults to the line item description.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{enriched-description-short}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'Shows the short material description if available; otherwise, it presents a shortened line item description.',
                          )}
                        </dd>

                        <dt>
                          <code>{'{tariff-number-description}'}</code>
                        </dt>
                        <dd>
                          {t(
                            'The description associated with the matched tariff number. Empty if no match is found or if tariff number matching is disabled.',
                          )}
                        </dd>
                      </dl>
                      <p>
                        {t(
                          'Combine these variables with a separator enclosed in brackets to format your line item descriptions effectively. The separator is only added if the preceding variable contains data.',
                        )}
                      </p>
                      <h4>{t('Examples')}:</h4>
                      <ul>
                        <li>
                          <code>
                            {
                              '{tariff-number-description}[ - ]{enriched-description}'
                            }
                          </code>
                        </li>
                        <li>
                          <code>
                            {
                              '{tariff-number-description}[ - ]{enriched-description-short}'
                            }
                          </code>
                        </li>
                        <li>
                          <code>
                            {
                              '{tariff-number-description}[ - ]{line-item-description}'
                            }
                          </code>
                        </li>
                        <li>
                          <code>
                            {
                              '{tariff-number-description} - {line-item-description-short}'
                            }
                          </code>
                        </li>
                        <li>
                          <code>{'{enriched-description}'}</code>
                        </li>
                        <li>
                          <code>{'{enriched-description-short}'}</code>
                        </li>
                      </ul>
                    </Space>
                  </Card>
                </Form>
              )}
            </Card>
          )}
        </>
      ),
      isCheckbox: true,
    },
    {
      title: t('Eliminate article number from item description'),
      help: t(
        'If you activate this, Digicust will remove the article number from the item description. If you deactivate this, Digicust will keep the article number in the item description.',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.eliminateArticleNumberFromDescription,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            eliminateArticleNumberFromDescription: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
  ];

  return { fields };
};

export default function GoodsDescriptionSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useGoodsDescriptionSettingsMapper({
    execStrategy,
    onValueChange,
  });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
