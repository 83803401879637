import {
  AutoComplete,
  Button,
  Card,
  Flex,
  Popover,
  Space,
  Typography,
} from 'antd';
import {
  AggregatedCaseDataModel,
  CompanyModel,
  TariffSystem,
} from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDataContext } from '../AppDataProvider';
import CustomsOffices from './general-tab/CustomsOffices';
import { useGeneralDetailsFields } from './general-tab/GeneralDetailsInput';
import PreliminaryDocuments from './general-tab/PreliminaryDocuments';
import ContainersInput from './shipment-tab/ContainersInput';
import { useFreightDocumentMapper } from './shipment-tab/FreightDocumentInput';
import MeansOfTransportation from './shipment-tab/MeansOfTransportationInput';
import PackagesInput from './shipment-tab/PackagesInput';
import SealsInput from './shipment-tab/SealsInput';
import CompanyDetailsInput from './stakeholder-tab/CompanyDetailsInput';
import { useStakeholderFields } from './stakeholder-tab/StakeholderDocumentInput';
import { CaseFieldMapper } from './types';

export function useCaseFieldMapper({
  caseData,
  updateItem,
}: {
  caseData: AggregatedCaseDataModel;
  updateItem: (caseData: AggregatedCaseDataModel) => void;
}) {
  const { t } = useTranslation();
  const { projectDetails } = useAppDataContext();

  const { fields: generalFields } = useGeneralDetailsFields({
    caseData,
    updateItem,
    tariffSystem: projectDetails?.tariffNumberTreeSystem || TariffSystem.DE,
  });

  const { fields: fdFields } = useFreightDocumentMapper({
    caseData,
    updateItem,
  });

  const { companies } = useStakeholderFields();

  const caseFields: CaseFieldMapper[] = [
    ...generalFields,
    ...fdFields,
    ...companies.map((com) => ({
      title: com.title,
      getValue: () => (caseData as any)?.[com.property],
      updateProperty: (company: CompanyModel) => {
        updateItem({ ...(caseData || {}), [com.property]: company });
      },
      renderComponent: (
        value: CompanyModel,
        updateProperty: (company: CompanyModel) => void,
      ) => (
        <CompanyDetailsInput
          value={value}
          keyValue={com.title}
          onChange={(comp) => {
            updateProperty(comp);
          }}
        />
      ),
    })),
    {
      title: t('Custom Offices'),
      hideTitle: true,
      vertical: true,
      getValue: () => caseData?.customsOffices,
      updateProperty: (customsOffices) => {
        updateItem({ ...(caseData || {}), customsOffices });
      },
      renderComponent: (value, updateProperty) => (
        <CustomsOffices
          value={value}
          onValueChange={(offices) => {
            updateProperty(offices);
          }}
        />
      ),
    },
    {
      title: t('Preliminary Documents'),
      hideTitle: true,
      vertical: true,
      getValue: () => caseData?.preliminaryDocuments,
      updateProperty: (preliminaryDocuments) => {
        updateItem({ ...(caseData || {}), preliminaryDocuments });
      },
      renderComponent: (value, updateProperty) => (
        <PreliminaryDocuments
          value={value}
          onValueChange={(documents) => {
            updateProperty(documents);
          }}
        />
      ),
    },
    {
      title: t('Means Of Transportation'),
      hideTitle: true,
      vertical: true,
      getValue: () => caseData?.meansOfTransportation,
      updateProperty: (meansOfTransportation) => {
        updateItem({ ...(caseData || {}), meansOfTransportation });
      },
      renderComponent: (value, updateProperty) => (
        <MeansOfTransportation
          value={value}
          onValueChange={(meansOfTransportation) => {
            updateProperty(meansOfTransportation);
          }}
        />
      ),
    },
    {
      title: t('Packages'),
      hideTitle: true,
      vertical: true,
      getValue: () => caseData?.packages,
      updateProperty: (packages) => {
        updateItem({ ...(caseData || {}), packages });
      },
      renderComponent: (value, updateProperty) => (
        <PackagesInput
          value={value}
          onValueChange={(packageItem) => {
            updateProperty(packageItem);
          }}
        />
      ),
    },
    {
      title: t('Containers'),
      hideTitle: true,
      vertical: true,
      getValue: () => caseData?.containers,
      updateProperty: (containers) => {
        updateItem({ ...(caseData || {}), containers });
      },
      renderComponent: (value, updateProperty) => (
        <ContainersInput
          value={value}
          onValueChange={(containerItem) => {
            updateProperty(containerItem);
          }}
        />
      ),
    },
    {
      title: t('Seals'),
      hideTitle: true,
      vertical: true,
      getValue: () => caseData?.seals,
      updateProperty: (seals) => {
        updateItem({ ...(caseData || {}), seals });
      },
      renderComponent: (value, updateProperty) => (
        <SealsInput
          value={value}
          onValueChange={(seals) => {
            updateProperty(seals);
          }}
        />
      ),
    },
  ];

  return {
    caseFields: caseFields.sort((a, b) =>
      a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
    ),
  };
}

export default function SearchableCaseFields({
  caseData,
  updateItem,
}: {
  caseData: AggregatedCaseDataModel;
  updateItem: (caseData: AggregatedCaseDataModel) => void;
}) {
  const { t } = useTranslation();
  const { caseFields } = useCaseFieldMapper({ caseData, updateItem });

  const [search, setSearch] = useState('');
  return (
    <Space direction="vertical" style={{ width: '100%', marginBottom: 24 }}>
      <Space size={0} direction="vertical" style={{ width: '100%' }}>
        <Typography.Text>{t('Search Case Details Properties')}</Typography.Text>

        <AutoComplete
          placeholder={t('Search Case Details Properties')}
          value={search}
          size="large"
          onChange={(value) => setSearch(value.toLowerCase())}
          options={caseFields
            .map((field) => ({
              label: field.title,
              value: field.title,
            }))
            .filter((cur) => cur.label.toLowerCase().includes(search))}
          style={{ width: '100%' }}
        />
      </Space>

      <Space
        direction="vertical"
        style={{ maxHeight: '400px', width: '100%', overflow: 'auto' }}
      >
        {caseFields
          .filter(
            (field) =>
              !!field.getValue() ||
              field.title.toLowerCase().includes(search || 'placeholder'),
          )
          .map((field) => {
            return (
              <Card
                style={{
                  borderColor: field.title
                    .toLowerCase()
                    .includes(search || 'placeholder')
                    ? '#1677ff'
                    : '',
                }}
              >
                <Flex vertical={field.vertical} justify="space-between" gap={5}>
                  {!field.hideTitle && (
                    <Popover placement="right" content={field.help}>
                      <Typography.Text style={{ fontWeight: 600 }}>
                        {field.title}
                      </Typography.Text>
                    </Popover>
                  )}

                  {!field.getValue() && (
                    <div style={{ flex: 1, textAlign: 'end' }}>
                      <Space.Compact
                        direction="horizontal"
                        style={{ height: '100%' }}
                      >
                        {field.suggestions?.map((suggestion) => (
                          <Button
                            type="dashed"
                            size="small"
                            tabIndex={-1}
                            onClick={() => {
                              field.updateProperty(suggestion?.value);
                            }}
                          >
                            {suggestion?.title}
                          </Button>
                        ))}
                      </Space.Compact>
                    </div>
                  )}

                  <div style={{ maxWidth: '70%', minWidth: '40%' }}>
                    {field.renderComponent(
                      field.getValue(),
                      field.updateProperty,
                    )}
                  </div>
                </Flex>
              </Card>
            );
          })}
      </Space>
    </Space>
  );
}
