import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useContext } from 'react';
import { ThemeContext } from '../utils/ThemeContext';

export default function DCTooltip({ text }: { text: string }) {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <Tooltip title={text}>
      <QuestionCircleOutlined
        style={{ color: isDarkMode ? '#FFFFFF29' : '#00000073', margin: '4px' }}
      />
    </Tooltip>
  );
}
