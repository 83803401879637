import { Space, Typography } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import CardWrapper from '../../../Common/CardWrapper';
import AdditionalCostsSettings from './additional-costs-settings';
import ExportSettings from './export-settings';
import GoodsDescriptionSettings from './goods-description-settings';
import MasterDataSettings from './master-data-settings';
import OtherOptionsSettings from './other-options-settings';
import PackagesSettings from './packages-settings';
import PreferenceSettings from './preference-settings';
import TariffNumberSettings from './tariff-number-settings';
import TransitSettings from './transit-settings';
import WeightSettings from './weight-settings';
import DocumentCodesSettings from './document-codes-settings';

export const DataNormalizationCard = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CardWrapper
      alternative
      title={t('Data Normalization')}
      description={t('Specify how Digicust makes sense out of information.')}
      checked={execStrategy?.dataNormalization?.active}
      onChange={(e) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            active: e,
          },
        })
      }
    >
      {execStrategy.dataNormalization?.active && (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Title level={5}>{t('Tariff Number')}</Typography.Title>
          <TariffNumberSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>{t('Master Data')}</Typography.Title>
          <MasterDataSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>{t('Weight')}</Typography.Title>
          <WeightSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>
            {t('Goods Description')}
          </Typography.Title>
          <GoodsDescriptionSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>
            {t('Document Type Codes')}
          </Typography.Title>
          <DocumentCodesSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>{t('Additional Costs')}</Typography.Title>
          <AdditionalCostsSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>{t('Packages')}</Typography.Title>
          <PackagesSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>{t('Export')}</Typography.Title>
          <ExportSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>{t('Transit')}</Typography.Title>
          <TransitSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>{t('Preference')}</Typography.Title>
          <PreferenceSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />

          <Typography.Title level={5}>{t('Other options')}</Typography.Title>
          <OtherOptionsSettings
            execStrategy={execStrategy}
            onValueChange={onValueChange}
          />
        </Space>
      )}
    </CardWrapper>
  );
};
