import Editor from '@monaco-editor/react';
import { Button, Flex, Form, Input, Layout, Tabs } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import FirstTimeTour from '../../../Components/FirstTimeTour';
import { ExecutionStrategyRepository } from '../../../utils/repositories/executionStrategy.repository';
import Events from './Events';
import ProcessingTab from './Processing';
import RulesTab from './Rules';
import SubmissionsTab from './Submissions';

const ExecutionStrategyComponent = ({
  value,
  onValueChange,
  noAlias,
  saveable,
}: {
  value: ExecutionStrategy;
  onValueChange?: (value: ExecutionStrategy) => void;
  noAlias?: boolean;
  saveable?: boolean;
}) => {
  const [onSaving, setOnSaving] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const { t } = useTranslation();

  const onChange = (value: ExecutionStrategy) => {
    onValueChange && onValueChange(value);
    setHasChanged(true);
  };

  const refProcessing = useRef(null);
  const refRules = useRef(null);
  const refUpload = useRef(null);
  const refCustoms = useRef(null);
  const refSubmission = useRef(null);
  const refEvents = useRef(null);

  return (
    <Layout.Content>
      <Flex justify="space-between" gap={5}>
        {!noAlias && (
          <Form.Item label="Alias" style={{ margin: 0, width: '100%' }}>
            <Input
              placeholder="Alias"
              value={value?.alias}
              onChange={(e) => onChange({ ...value, alias: e.target.value })}
            />
          </Form.Item>
        )}
        {saveable && (
          <Button
            loading={onSaving}
            onClick={async () => {
              setOnSaving(true);
              await ExecutionStrategyRepository.save(
                value?.customerId,
                value?.projectId,
                value,
              );
              setHasChanged(false);
              setOnSaving(false);
            }}
            disabled={!hasChanged}
            type="primary"
          >
            {t('Save Strategy')}
          </Button>
        )}
      </Flex>

      <Tabs
        tabBarStyle={{ padding: '0 16px' }}
        items={[
          {
            label: <span ref={refProcessing}>{t('Processing')}</span>,
            key: 'processing',
            children: <ProcessingTab value={value} onChange={onChange} />,
          },
          {
            label: <span ref={refRules}>{t('Rules')}</span>,
            key: 'rules',
            children: <RulesTab value={value} onValueChange={onChange} />,
          },
          {
            label: <span ref={refSubmission}>{t('Integration')}</span>,
            key: 'submission',
            children: <SubmissionsTab data={value} onValueChange={onChange} />,
          },
          {
            label: <span ref={refEvents}>{t('Events')}</span>,
            key: 'events',
            children: <Events value={value} onValueChange={onChange} />,
          },

          {
            label: 'JSON',
            key: 'json',
            children: [
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: 'calc(100vh - 200px)',
                  overflowY: 'scroll',
                  gap: '5px',
                  height: '100vh',
                  flexBasis: '100%',
                  padding: '0 16px',
                }}
              >
                <Editor
                  path={value.id}
                  value={JSON.stringify(value, null, 4)}
                  onChange={(value) => {
                    try {
                      onChange(JSON.parse(value || '{}'));
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  language="json"
                />
              </div>,
            ],
          },
        ]}
      />

      <FirstTimeTour
        tourId="executionStrategyTour"
        steps={[
          {
            title: 'Set Up How Cases Are Processed',
            description:
              "We understand, that every organization has different requirements and processes. That's why we allow you to set up how cases are processed. You can configure how tariff numbers are handled, how master data is used and how to read the PDF documents.",
            target: refProcessing.current,
          },
          {
            title: 'Create Rules',
            description:
              'Rules are simple conditions that are evaluated for each case. If a rule is true, the case is processed according to the rule. If no rule is true, the case is processed according to the default settings. You can add as many rules as you want.',
            target: refRules.current,
          },
          {
            title: 'Upload Your Way',
            description:
              'You can upload cases to Digicust in different ways. You can upload them manually, send them via email or upload them via SFTP. You can also set up a whitelist of email addresses that are allowed to upload cases.',
            target: refUpload.current,
          },
          {
            title: 'Specify Customs Details',
            description:
              'You can specify customs details like the GRN and the declarant representative. These details are used for the customs declaration.',
            target: refCustoms.current,
          },
          {
            title: 'Submit Customs Declaration',
            description:
              'You can specify how the customs declaration is submitted to your customs software. Choose between vendors such as Dakosy, AEB, LDV or DBH.',
            target: refSubmission.current,
          },
          {
            title: 'Events',
            description:
              'You can set up events that are triggered when a case is processed. You can send emails, call webhooks or send files via SFTP.',
            target: refEvents.current,
          },
        ]}
      />
    </Layout.Content>
  );
};

export default ExecutionStrategyComponent;
