import { Checkbox } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { t } from 'i18next';
import { DCTooltip } from '../../../../common';
import DebouncedInput from '../../../../Components/Inputs/Common/DebouncedInput';
import DebouncedTextArea from '../../../../Components/Inputs/Common/DebouncedTextAirea';
import CardWrapper from '../../Common/CardWrapper';

export default function ExtractionSettingsDexter({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  return (
    <CardWrapper>
      <div className="hoverable">
        {t('Specification')}
        <DCTooltip
          text={t(
            'Specify a specific model that you want to use. If you leave this empty, the latest model will be used.',
          )}
        />
        <DebouncedInput
          size="small"
          value={execStrategy?.dataExtraction?.engineSpecification}
          onChange={(value) =>
            onValueChange({
              ...execStrategy,
              dataExtraction: {
                ...(execStrategy.dataExtraction || {}),
                engineSpecification: value,
              },
            })
          }
        />
      </div>

      <div className="hoverable">
        {t('Prompt remarks')}
        <DCTooltip
          text={t(
            'Provide additional information in natural language on how data shall be extracted from documents.',
          )}
        />
        <DebouncedTextArea
          size="small"
          value={execStrategy?.dataExtraction?.promptRemarks}
          onChange={(value) =>
            onValueChange({
              ...execStrategy,
              dataExtraction: {
                ...(execStrategy.dataExtraction || {}),
                promptRemarks: value,
              },
            })
          }
        />
      </div>

      <Checkbox
        className="hoverable"
        checked={execStrategy?.dataExtraction?.requireHumanAnnotation}
        onChange={(e) =>
          onValueChange({
            ...execStrategy,
            dataExtraction: {
              ...(execStrategy.dataExtraction || {}),
              requireHumanAnnotation: e.target.checked,
            },
          })
        }
      >
        {t('Require Human Annotation')}
        <DCTooltip
          text={t(
            'Specify whether to require human annotation. If not activated, then the engine will extract data and forward to the proceeding services automatically.',
          )}
        />
      </Checkbox>
    </CardWrapper>
  );
}
