import { Checkbox, Flex, Select } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { WiStars } from 'react-icons/wi';
import { DCTooltip } from '../../../../../common';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';

export const useDocumentCodesSettingsMapper = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Automatically add negative codings'),
      help: t(
        'If you activate this, Digicust will always assume negative codings (Yxxx) necessary per customs tariff number and add them to goods.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.documentCodeEnrichment
          ?.addNegativeCodings,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            documentCodeEnrichment: {
              ...(execStrategy.dataNormalization?.documentCodeEnrichment || {}),
              addNegativeCodings: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          <WiStars
            color="rgb(22, 119, 255)"
            size={26}
            style={{ marginTop: -20, marginBottom: -8 }}
          />
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Blacklist codes'),
      help: t(
        `If you don't want certain codes to be used, you can blacklist them here.`,
      ),
      getValue: () =>
        execStrategy.dataNormalization?.documentCodeEnrichment
          ?.blacklistCodes || [],
      updateProperty: (codes) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            documentCodeEnrichment: {
              ...(execStrategy.dataNormalization?.documentCodeEnrichment || {}),
              blacklistCodes: codes,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <div className="hoverable" style={{ display: 'inline-block' }}>
          {title}
          {help && <DCTooltip text={help} />}
          <Select
            popupMatchSelectWidth={false}
            size="small"
            showSearch
            style={{ minWidth: '150px' }}
            mode="tags"
            placeholder={t('Add blacklist code')}
            value={value}
            onChange={(codes) => updateProperty(codes)}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                ?.toLowerCase?.()
                ?.includes?.(input?.toLowerCase?.())
            }
          />
        </div>
      ),
    },
    {
      title: t('No document codes on line items'),
      help: t(
        'If you activate this, Digicust will remove all document codes from the line items.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.documentCodeEnrichment
          ?.noLineItemDocumentCodes,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            documentCodeEnrichment: {
              ...(execStrategy.dataNormalization?.documentCodeEnrichment || {}),
              noLineItemDocumentCodes: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Assume proforma invoice'),
      help: t('Will assume that uploaded invoice is a proforma invoice.'),
      getValue: () =>
        execStrategy.dataNormalization?.defaultInvoiceType === 'proforma',
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            defaultInvoiceType: checked ? 'proforma' : undefined,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Prefer single waybill code'),
      help: t(
        'Specify whether Digicust should use a single waybill code (e.g. only 7HHF) or separate waybill codes (e.g. 7HHF and N730).',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.waybillCodePreference === 'singleCode',
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            waybillCodePreference: checked ? 'singleCode' : 'seperateCodes',
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
  ];

  return { fields };
};

export default function DocumentCodesSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useDocumentCodesSettingsMapper({
    execStrategy,
    onValueChange,
  });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
