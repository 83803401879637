import { Input, InputRef } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import React, { useEffect, useState } from 'react';

interface DebouncedInputProps {
  value?: string;
  onChange?: (e?: string) => void;
  placeholder?: string;
  id?: string;
  size?: 'large' | 'middle' | 'small';
  readOnly?: boolean;
  style?: React.CSSProperties;
}

const DebouncedTextArea = React.forwardRef<InputRef, DebouncedInputProps>(
  ({ value, onChange, placeholder, id, style, size, readOnly }, ref) => {
    const [curValue, setCurValue] = useState<string>();

    useEffect(() => {
      if (value) setCurValue(value);
    }, [value]);

    const props: TextAreaProps = {
      style: style,
      variant: 'filled',
      id: id,
      size: size,
      readOnly: readOnly,
      placeholder: placeholder,
      title: curValue,
      value: curValue,
      onChange: (e) => setCurValue(e.target.value),
      onBlur: () => onChange && onChange(curValue),
    };

    return <Input.TextArea ref={ref} {...props} />;
  },
);

export default DebouncedTextArea;
