import { Checkbox, Flex, Select } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { WiStars } from 'react-icons/wi';
import { DCTooltip } from '../../../../common';
import DebouncedInput from '../../../../Components/Inputs/Common/DebouncedInput';
import DebouncedTextArea from '../../../../Components/Inputs/Common/DebouncedTextAirea';
import { CaseFieldMapper } from '../../../CustomsCaseDetails/types';
import CardWrapper from '../../Common/CardWrapper';
import ExtractionSettingsDexter from './extraction-seetting-dexter';
import ExtractionSettingsLLM from './extraction-settings-llm';

export const useExtractionServiceMapperES = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Extraction Engine'),
      help: t(
        'Different engines have different advantages and disadvantages. Dexter IDP Elevator is fast, but less reliable than Dexter IDP Escalator.',
      ),
      getValue: () => execStrategy?.dataExtraction?.engine || 'default',
      updateProperty: (engine) => {
        onValueChange({
          ...execStrategy,
          dataExtraction: {
            ...(execStrategy.dataExtraction || {}),
            engine,
          },
        });
      },
      renderComponent: (value, updateProperty) => {
        return (
          <>
            <Select
              popupMatchSelectWidth={false}
              size="small"
              placeholder={t('Engine')}
              optionFilterProp="children"
              value={value}
              onChange={(e) => updateProperty(e || 'default')}
            >
              <Select.Option value={'default'}>
                Dexter IDP Escalator
              </Select.Option>
              <Select.Option value={'dexter_experimental'}>
                Dexter IDP Elevator
              </Select.Option>
              <Select.Option value={'llm'}>LLM Extractor</Select.Option>
            </Select>

            {execStrategy?.dataExtraction?.engine === 'llm' && (
              <ExtractionSettingsLLM
                execStrategy={execStrategy}
                onValueChange={onValueChange}
              />
            )}

            {(execStrategy?.dataExtraction?.engine || 'default') !==
              'default' && (
              <ExtractionSettingsDexter
                execStrategy={execStrategy}
                onValueChange={onValueChange}
              />
            )}
          </>
        );
      },
    },
    {
      title: t('Classify using dexter'),
      help: t(
        'Weather to classify document manually or let dexter do the automated classification.',
      ),
      getValue: () => execStrategy.dataExtraction?.classifyUsingDexter,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataExtraction: {
            ...(execStrategy.dataExtraction || {}),
            classifyUsingDexter: checked,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Extract and use email content'),
      help: t(
        'Digicust can read information from emails. If you activate this, Digicust will extract and use information from the email content.',
      ),
      getValue: () => execStrategy.dataNormalization?.normalizeEmailDocuments,
      updateProperty: (checked) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            normalizeEmailDocuments: checked,
          },
        });
      },
      renderComponent: (value, updateProperty, title, help) => {
        return (
          <>
            <Checkbox
              className="hoverable"
              checked={value}
              onChange={(e) => updateProperty(e.target.checked)}
            >
              <WiStars
                color="rgb(22, 119, 255)"
                size={26}
                style={{ marginTop: -20, marginBottom: -8 }}
              />
              {title}
              {help && <DCTooltip text={help} />}
            </Checkbox>

            {execStrategy.dataNormalization?.normalizeEmailDocuments && (
              <div className="hoverable">
                {t('Email normalization remarks')}
                <DCTooltip
                  text={t(
                    'Add remarks for the email extraction. The system will use this information to extract data from emails.',
                  )}
                />
                <DebouncedInput
                  value={
                    execStrategy?.dataNormalization
                      ?.normalizeEmailDocumentsOptions?.remarks
                  }
                  onChange={(value) =>
                    onValueChange({
                      ...execStrategy,
                      dataNormalization: {
                        ...(execStrategy.dataNormalization || {}),
                        normalizeEmailDocumentsOptions: {
                          ...(execStrategy.dataNormalization
                            ?.normalizeEmailDocumentsOptions || {}),
                          remarks: value,
                        },
                      },
                    })
                  }
                />
              </div>
            )}
          </>
        );
      },
      isCheckbox: true,
    },
    {
      title: t('Annotation Remarks'),
      help: t(
        'Provide additional information in natural language on how data shall be annotated for training.',
      ),
      getValue: () => execStrategy?.dataExtraction?.annotationRemarks,
      updateProperty: (annotationRemarks) => {
        onValueChange({
          ...execStrategy,
          dataExtraction: {
            ...(execStrategy.dataExtraction || {}),
            annotationRemarks,
          },
        });
      },
      renderComponent: (value, updateProperty) => {
        return (
          <DebouncedTextArea
            size="small"
            value={value}
            onChange={(value) => updateProperty(value)}
          />
        );
      },
    },
  ];

  return { fields };
};

export const DataExtractionCard = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const { fields } = useExtractionServiceMapperES({
    execStrategy,
    onValueChange,
  });

  return (
    <CardWrapper
      title={t('Data Extraction')}
      alternative
      tooltip={t(
        'Specify how data shall be extracted from documents. If you deactivate this, no data will be extracted from documents.',
      )}
      description={t('Specify how Digicust extracts data from documents.')}
      checked={execStrategy?.dataExtraction?.active}
      onChange={(e) =>
        onValueChange({
          ...execStrategy,
          dataExtraction: {
            ...(execStrategy.dataExtraction || {}),
            active: e,
          },
        })
      }
    >
      {execStrategy.dataExtraction?.active && (
        <Flex vertical gap={6}>
          {fields.map((field) => {
            if (field.isCheckbox) {
              return field.renderComponent(
                field.getValue(),
                field.updateProperty,
                field.title,
                field.help as string,
              );
            }

            return (
              <div className="hoverable" style={{ display: 'inline-block' }}>
                {field.title}
                <DCTooltip text={field.help as string} />
                {field.renderComponent(field.getValue(), field.updateProperty)}
              </div>
            );
          })}
        </Flex>
      )}
    </CardWrapper>
  );
};
