import { Checkbox, Flex, InputNumber, Select } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import CardWrapper from '../../../Common/CardWrapper';

export const useWeightSettingsMapper = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Use Gross Weight from Package Information Only'),
      help: t(
        'This function takes the gross weight only from invoices that have package information, such as the type and number of packages. If the invoice doesn’t include these details, its weight will be ignored in the calculation of the total gross weight.',
      ),
      getValue: () =>
        execStrategy.dataNormalization
          ?.aggregateGrossWeightFromInvoicesWithPackageInformationOnly,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            aggregateGrossWeightFromInvoicesWithPackageInformationOnly: checked,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Force gross weight to first line item'),
      help: t(
        'You can choose to force the line item gross weight to the first line item here.',
      ),
      getValue: () =>
        execStrategy?.dataNormalization?.lineItemGrossWeightStrategy ===
        'forceToFirstLineItem',
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            lineItemGrossWeightStrategy: checked
              ? 'forceToFirstLineItem'
              : 'default',
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Prefer weight information from head'),
      help: t(
        'Weight information can come from line items or from the document head. Activate, if you want to prefer weight information from the document head.',
      ),
      getValue: () => execStrategy?.dataNormalization?.preferWeightFromHead,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            preferWeightFromHead: checked || undefined,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Calculate line item weight'),
      help: t(
        'Often, no specific weight information is given for line items. Digicust automatically calculates the weight of line items based on the total weight of the document. You can choose how the weight is distributed here.',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.calculateLineItemWeight || 'byQuantity',
      updateProperty: (value) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            calculateLineItemWeight: value,
          },
        }),
      renderComponent: (value, updateProperty) => (
        <Select
          popupMatchSelectWidth={false}
          size="small"
          placeholder={t('Line Item Weight Strategy')}
          value={value}
          onChange={(value) => updateProperty(value)}
        >
          <Select.Option value="byQuantity">{t('By Quantity')}</Select.Option>
          <Select.Option value="byTotalValue">
            {t('By Total Value')}
          </Select.Option>
        </Select>
      ),
    },
    {
      title: t('Calculate weight'),
      help: t(
        'Define how net weight and gross weight are being calculated (if not explicitly given on documents).',
      ),
      getValue: () => !!execStrategy.dataNormalization?.calculateWeight,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            calculateWeight: checked ? {} : undefined,
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <>
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>

          {execStrategy.dataNormalization?.calculateWeight && (
            <CardWrapper>
              <div className="hoverable" style={{ display: 'inline-block' }}>
                {t('Gross weight from net weight')}
                <DCTooltip
                  text={t(
                    'Often, no specific gross weight is available on documents. In that case, Digicust calculates the gross weight from the net weight. You can choose how the gross weight is calculated here.',
                  )}
                />
                <InputNumber
                  size="small"
                  defaultValue={1.1}
                  value={
                    execStrategy.dataNormalization?.calculateWeight
                      ?.grossWeightFromNetWeight
                  }
                  type="number"
                  onChange={(e) =>
                    onValueChange({
                      ...execStrategy,
                      dataNormalization: {
                        ...(execStrategy.dataNormalization || {}),
                        calculateWeight: {
                          ...(execStrategy.dataNormalization?.calculateWeight ||
                            {}),
                          grossWeightFromNetWeight: e || undefined,
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="hoverable" style={{ display: 'inline-block' }}>
                {t('Net weight from gross weight')}
                <DCTooltip
                  text={t(
                    'Often, no specific net weight is available on documents. In that case, Digicust calculates the net weight from the gross weight. You can choose how the net weight is calculated here.',
                  )}
                />
                <InputNumber
                  value={
                    execStrategy.dataNormalization?.calculateWeight
                      ?.netWeightFromGrossWeight || 0.9
                  }
                  size="small"
                  type="number"
                  onChange={(e) =>
                    onValueChange({
                      ...execStrategy,
                      dataNormalization: {
                        ...(execStrategy.dataNormalization || {}),
                        calculateWeight: {
                          ...(execStrategy.dataNormalization?.calculateWeight ||
                            {}),
                          netWeightFromGrossWeight: e || 0.9,
                        },
                      },
                    })
                  }
                />
              </div>
            </CardWrapper>
          )}
        </>
      ),
      isCheckbox: true,
    },
  ];

  return { fields };
};

export default function WeightSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useWeightSettingsMapper({ execStrategy, onValueChange });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
