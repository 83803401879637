import { Card, Checkbox, Flex, Form, Select, Space, Typography } from 'antd';
import { ClassificationTypes, ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { WiStars } from 'react-icons/wi';
import { DCTooltip } from '../../../../../common';
import CountrySelect from '../../../../../Components/Inputs/CountrySelect';
import JSONataInput from '../../../../../Components/Inputs/JSONataInput';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';
import { cardBorderCss } from '../../../Common/CardWrapper';
import NumberInput from '../../../../../Components/Inputs/Common/NumberInput';

const sampleJSONata = `{
  'tariffNumber': '1234567890',
  'quantity': 1234,
  'tariffNumberDescription':
    'Einrichtungen, Maschinen, Apparate und Geräte zur Kälteerzeugung',
  'lineItemDescription': 'Orsiro Mission 2.5/9 PTCA-Stents ',
  'lineItemDescriptionShort': 'Orsiro Mission PTCA-Stents ',
  'materialDescription': 'PCTA-Stents',
  'enrichedDescription': 'PCTA-Stents',
  'enrichedDescriptionShort': 'Stents',
  'preferMaterialMasterData': false,
  'materialMatched': true,
  'useCustomsTariffNumberDescription': true,
  'useLineItemDescriptionShortening': true,
  'materialMatchedByTariffNumber': true,
  'materialMatchedByMaterialNr': false
}`;

export const useTariffNumberSettingsMapper = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Tariff classification'),
      help: t(
        'Automatically classifies goods based on goods descriptions up to the 11th digit.',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.customsTariffNumberNormalizationSettings
          ?.tariffClassification?.active,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberNormalizationSettings: {
              ...(execStrategy.dataNormalization
                ?.customsTariffNumberNormalizationSettings || {}),
              tariffClassification: {
                ...(execStrategy?.dataNormalization
                  ?.customsTariffNumberNormalizationSettings
                  ?.tariffClassification || {}),
                active: checked,
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <>
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            <WiStars
              color="rgb(22, 119, 255)"
              size={26}
              style={{ marginTop: -20, marginBottom: -8 }}
            />
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
          {value && (
            <Card style={cardBorderCss}>
              <Form layout="vertical">
                <Form.Item label={t('Define the classification process')}>
                  <Select
                    popupMatchSelectWidth={false}
                    value={
                      execStrategy?.dataNormalization
                        ?.customsTariffNumberNormalizationSettings
                        ?.tariffClassification?.mode ||
                      ClassificationTypes.classifyAndSuggest
                    }
                    onChange={(classificationType) =>
                      onValueChange({
                        ...execStrategy,
                        dataNormalization: {
                          ...(execStrategy.dataNormalization || {}),
                          customsTariffNumberNormalizationSettings: {
                            ...(execStrategy.dataNormalization
                              ?.customsTariffNumberNormalizationSettings || {}),
                            tariffClassification: {
                              ...(execStrategy?.dataNormalization
                                ?.customsTariffNumberNormalizationSettings
                                ?.tariffClassification || {}),
                              mode: classificationType,
                              active:
                                classificationType !==
                                ClassificationTypes.noClassification,
                            },
                          },
                        },
                      })
                    }
                  >
                    <Select.Option
                      value={ClassificationTypes.classifyOnlyMissing}
                    >
                      {t('Classify only where missing')}
                    </Select.Option>
                    <Select.Option
                      value={ClassificationTypes.classifyAndSuggest}
                    >
                      {t('Classify and suggest')}
                    </Select.Option>
                    <Select.Option
                      value={ClassificationTypes.classifyAndReplace}
                    >
                      {t('Classify and replace')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Card>
          )}
        </>
      ),
      isCheckbox: true,
    },
    {
      title: t('Map foreign tariff number to country'),
      help: t('Map to foreign tariff number for country'),
      getValue: () =>
        execStrategy.dataNormalization?.customsTariffNumberNormalizationSettings
          ?.mapForeignTariffNumberAlpha2Code,
      updateProperty: (country) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberNormalizationSettings: {
              ...(execStrategy.dataNormalization
                ?.customsTariffNumberNormalizationSettings || {}),
              mapForeignTariffNumberAlpha2Code: country?.alpha2Code,
            },
          },
        }),
      renderComponent: (value, updateProperty) => (
        <div style={{ width: 250 }}>
          <CountrySelect
            value={{ alpha2Code: value }}
            onChange={(value) => updateProperty(value)}
          />
        </div>
      ),
    },
    {
      title: t('Auto-fix tariff numbers'),
      help: t(
        'Transforms foreign tariff numbers (e.g. Chinese) into tariff numbers in the target tariff tree (e.g. German EZT).',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.customsTariffNumberNormalizationSettings
          ?.autoFixTariffNumbers,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberNormalizationSettings: {
              ...(execStrategy.dataNormalization
                ?.customsTariffNumberNormalizationSettings || {}),
              autoFixTariffNumbers: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          <WiStars
            color="rgb(22, 119, 255)"
            size={26}
            style={{ marginTop: -20, marginBottom: -8 }}
          />
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Strip tariff number'),
      help: t(
        'Strips tariff numbers that we find on e.g. invoices to a shorter version for compatibility.',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.customsTariffNumberNormalizationSettings
          ?.customsTariffNumberLength,
      updateProperty: (length) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberNormalizationSettings: {
              ...(execStrategy.dataNormalization
                ?.customsTariffNumberNormalizationSettings || {}),
              customsTariffNumberLength: length,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <>
          <Checkbox
            className="hoverable"
            checked={!!value}
            onChange={(e) => updateProperty(e.target.checked ? 6 : undefined)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
          {value && (
            <Card style={cardBorderCss}>
              <Form layout="vertical">
                <Form.Item label={t('Strip tariff number to')}>
                  <Select
                    popupMatchSelectWidth={false}
                    value={value || 6}
                    options={[
                      { label: t('First 6 digits'), value: 6 },
                      { label: t('First 8 digits'), value: 8 },
                      { label: t('First 10 digits'), value: 10 },
                      { label: t('First 11 digits'), value: 11 },
                    ]}
                    onChange={(val) => updateProperty(val)}
                  />
                </Form.Item>
              </Form>
            </Card>
          )}
        </>
      ),
      isCheckbox: true,
    },
    {
      title: t('Use tariff number as item description'),
      help: t(
        'Uses the description of the tariff number automatically as item description.',
      ),
      getValue: () =>
        execStrategy.dataNormalization?.customsTariffNumberNormalizationSettings
          ?.useAsItemDescription,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberNormalizationSettings: {
              ...(execStrategy.dataNormalization
                ?.customsTariffNumberNormalizationSettings || {}),
              useAsItemDescription: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <>
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) => updateProperty(e.target.checked)}
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
          {value && (
            <Card style={cardBorderCss}>
              <Form layout="vertical">
                <Form.Item label={t('Source')}>
                  <Select
                    popupMatchSelectWidth={false}
                    value={
                      execStrategy?.dataNormalization
                        ?.customsTariffNumberNormalizationSettings
                        ?.itemDescriptionSource || 'description'
                    }
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        dataNormalization: {
                          ...(execStrategy.dataNormalization || {}),
                          customsTariffNumberNormalizationSettings: {
                            ...(execStrategy.dataNormalization
                              ?.customsTariffNumberNormalizationSettings || {}),
                            itemDescriptionSource: e,
                          },
                        },
                      })
                    }
                  >
                    <Select.Option value="description">
                      {t('Official Description')}
                    </Select.Option>
                    <Select.Option value="altDescription">
                      {t('Improved Description')}
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Space style={{ marginBottom: 20 }}>
                  <Checkbox
                    className="hoverable"
                    checked={
                      execStrategy.dataNormalization?.omitLineItemDescription
                    }
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        dataNormalization: {
                          ...(execStrategy.dataNormalization || {}),
                          omitLineItemDescription: e.target.checked,
                        },
                      })
                    }
                  >
                    {t('Omit document description')}
                    <DCTooltip
                      text={t(
                        'When enabled, position descriptions will be omitted from the final goods description (if possible, if no other description is available it will be used).',
                      )}
                    />
                  </Checkbox>
                  <Checkbox
                    className="hoverable"
                    checked={
                      execStrategy.dataNormalization
                        ?.customsTariffNumberNormalizationSettings
                        ?.hideOriginalItemDescription
                    }
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        dataNormalization: {
                          ...(execStrategy.dataNormalization || {}),
                          customsTariffNumberNormalizationSettings: {
                            ...(execStrategy.dataNormalization
                              ?.customsTariffNumberNormalizationSettings || {}),
                            hideOriginalItemDescription: e.target.checked,
                          },
                        },
                      })
                    }
                  >
                    {t('Hide Original Item Description')}
                  </Checkbox>

                  <Checkbox
                    className="hoverable"
                    checked={
                      execStrategy.dataNormalization
                        ?.customsTariffNumberNormalizationSettings
                        ?.preferMasterDataDescription
                    }
                    onChange={(e) =>
                      onValueChange({
                        ...execStrategy,
                        dataNormalization: {
                          ...(execStrategy.dataNormalization || {}),
                          customsTariffNumberNormalizationSettings: {
                            ...(execStrategy.dataNormalization
                              ?.customsTariffNumberNormalizationSettings || {}),
                            preferMasterDataDescription: e.target.checked,
                          },
                        },
                      })
                    }
                  >
                    {t('Prefer Description from Master Data')}
                  </Checkbox>
                </Space>
                <div className="hoverable" style={{ display: 'inline-block' }}>
                  {t('Max Characters')}
                  <DCTooltip
                    text={t(
                      'Specifies the maximum length of the combined item description (e.g. prefix + tariff number description + suffix).',
                    )}
                  />
                  <NumberInput
                    size="small"
                    style={{ width: '120px' }}
                    min={0}
                    max={1000}
                    step={1}
                    value={Math.round(
                      execStrategy.dataNormalization
                        ?.customsTariffNumberNormalizationSettings
                        ?.combinedMaxDescriptionLength || 238,
                    )}
                    onChange={(val) =>
                      onValueChange({
                        ...execStrategy,
                        dataNormalization: {
                          ...(execStrategy.dataNormalization || {}),
                          customsTariffNumberNormalizationSettings: {
                            ...(execStrategy?.dataNormalization
                              ?.customsTariffNumberNormalizationSettings || {}),
                            combinedMaxDescriptionLength: val || undefined,
                          },
                        },
                      })
                    }
                  />
                </div>
              </Form>
            </Card>
          )}
        </>
      ),
      isCheckbox: true,
    },
    {
      title: t('Disable line item description shortening'),
      getValue: () =>
        execStrategy.dataNormalization?.customsTariffNumberNormalizationSettings
          ?.disableLineItemDescriptionShortening,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberNormalizationSettings: {
              ...(execStrategy.dataNormalization
                ?.customsTariffNumberNormalizationSettings || {}),
              disableLineItemDescriptionShortening: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Consider tariff number from invoice head'),
      getValue: () =>
        execStrategy.dataNormalization?.customsTariffNumberNormalizationSettings
          ?.considerCustomsTariffNumberFromInvoiceHead,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberNormalizationSettings: {
              ...(execStrategy.dataNormalization
                ?.customsTariffNumberNormalizationSettings || {}),
              considerCustomsTariffNumberFromInvoiceHead: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Ignore customs tariff number from invoice'),
      getValue: () =>
        execStrategy.dataNormalization?.customsTariffNumberNormalizationSettings
          ?.ignoreCustomsTariffNumbersFromInvoice,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberNormalizationSettings: {
              ...(execStrategy.dataNormalization
                ?.customsTariffNumberNormalizationSettings || {}),
              ignoreCustomsTariffNumbersFromInvoice: checked,
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Compliance Check'),
      help: t('Checks the validity of tariff numbers.'),
      getValue: () =>
        execStrategy.dataNormalization?.customsTariffNumberNormalizationSettings
          ?.complianceCheck?.active,
      updateProperty: (checked) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberNormalizationSettings: {
              ...(execStrategy.dataNormalization
                ?.customsTariffNumberNormalizationSettings || {}),
              complianceCheck: {
                ...(execStrategy.dataNormalization
                  ?.customsTariffNumberNormalizationSettings?.complianceCheck ||
                  {}),
                active: checked,
              },
            },
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <Checkbox
          className="hoverable"
          checked={value}
          onChange={(e) => updateProperty(e.target.checked)}
        >
          {title}
          {help && <DCTooltip text={help} />}
        </Checkbox>
      ),
      isCheckbox: true,
    },
    {
      title: t('Tariff number mapping'),
      help: t(
        'Custom formatting for transforming the (auto fixed) customs tariff number into a new tariff number.',
      ),
      getValue: () =>
        !!execStrategy?.dataNormalization?.customsTariffNumberMappingJsonAta,
      updateProperty: (mapping) =>
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            customsTariffNumberMappingJsonAta: mapping || '',
          },
        }),
      renderComponent: (value, updateProperty, title, help) => (
        <>
          <Checkbox
            className="hoverable"
            checked={value}
            onChange={(e) =>
              updateProperty(e.target.checked ? sampleJSONata : '')
            }
          >
            {title}
            {help && <DCTooltip text={help} />}
          </Checkbox>
          {value && (
            <Card style={cardBorderCss}>
              <Typography.Title level={4} style={{ margin: 0 }}>
                {t('JSONata Formatting Options')}
              </Typography.Title>
              <Typography.Text>
                {t(
                  'Custom formatting for transforming the (autofixed) customs tariff number into a new tariff number.',
                )}
              </Typography.Text>

              <JSONataInput
                inputValue={
                  execStrategy?.dataNormalization
                    ?.customsTariffNumberMappingJsonAta
                }
                onChange={(val) => updateProperty(val)}
                style={{ margin: '20px 0 20px 0' }}
                sampleInput={sampleJSONata}
              />
            </Card>
          )}
        </>
      ),
      isCheckbox: true,
    },
  ];

  return { fields };
};

export default function TariffNumberSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useTariffNumberSettingsMapper({
    execStrategy,
    onValueChange,
  });

  return (
    <Flex wrap vertical gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable">
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
