import { AutoComplete, Card, Flex, Input, Space, Typography } from 'antd';
import { LineItemModel, TariffSystem } from 'digicust_types';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import XKPreferenceSelect from '../../../../Components/Country-Specific/XK/PreferenceSelect';
import XKProcedureSelect from '../../../../Components/Country-Specific/XK/ProcedureSelect';
import CustomsTariffNumberSelect from '../../../../Components/custom-tariff-number-select';
import AmountOfMoneyInput from '../../../../Components/Inputs/AmountOfMoneyInput';
import CountrySelect from '../../../../Components/Inputs/CountrySelect';
import DocumentsTypeCode from '../../../../Components/Inputs/document-type-code-input';
import FreightCostDistributionInput from '../../../../Components/Inputs/FreightCostDistributionInput';
import GoodsDescriptionInput from '../../../../Components/Inputs/GoodsDescriptionInput';
import PreferenceSelect from '../../../../Components/Inputs/PreferenceSelect';
import ProcedureSelect from '../../../../Components/Inputs/ProcedureSelect';
import QuantityInput from '../../../../Components/Inputs/QuantityInput';
import SpecialMeasureUnit from '../../../../Components/Inputs/SpecialMeasureInput';
import WeightInput from '../../../../Components/Inputs/WeightInput';
import { CaseFieldMapper } from '../../types';

export const useLineItemsRenderer = ({
  item = {},
  updateItem,
  tariffSystem,
}: {
  item: LineItemModel;
  updateItem: (item: LineItemModel) => void;
  tariffSystem: string;
}) => {
  const { t } = useTranslation();

  const detailedView: CaseFieldMapper[] = [
    {
      title: t('Description'),
      getValue: () => item?.description,
      updateProperty: (description) => {
        updateItem({ ...item, description });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <GoodsDescriptionInput
          itemId={item?.id}
          value={value}
          onChange={(description) => {
            updateProperty(description);
          }}
        />
      ),
    },
    {
      title: t('Custom tariff number'),
      getValue: () => item?.customsTariffNumber,
      updateProperty: (tariffNumber) => {
        updateItem({
          ...item,
          customsTariffNumber: {
            ...(item?.customsTariffNumber || {}),
            ...(tariffNumber || {}),
          },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <CustomsTariffNumberSelect
          itemId={item?.id}
          customsTariffNumber={value}
          onChange={(tariffNumber) => {
            updateProperty(tariffNumber);
          }}
        />
      ),
    },
    {
      title: t('Documents Type Codes'),
      getValue: () => item?.documents,
      updateProperty: (documents) => {
        updateItem({ ...item, documents: [...documents] });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <DocumentsTypeCode
          value={value}
          onChange={(documents) => {
            updateProperty(documents);
          }}
        />
      ),
    },
    tariffSystem === 'XK'
      ? {
          title: `${t('Preference')} XK`,
          getValue: () => item?.countrySpecific?.XK?.Preference_code,
          updateProperty: (preferenceCode) => {
            updateItem({
              ...item,
              countrySpecific: {
                XK: {
                  ...(item?.countrySpecific?.XK || {}),
                  Preference_code: preferenceCode || undefined,
                },
              },
            });
          },
          renderComponent: (value, updateProperty: (data: any) => void) => (
            <XKPreferenceSelect
              value={value}
              onChange={(e) => {
                updateProperty(e);
              }}
            />
          ),
        }
      : {
          title: t('Preference'),
          getValue: () => item?.preferenceState,
          updateProperty: (preferenceState) => {
            updateItem({
              ...item,
              preferenceState: {
                ...(preferenceState || {}),
              },
            });
          },
          renderComponent: (value, updateProperty: (data: any) => void) => (
            <PreferenceSelect
              value={value}
              onChange={(e) => {
                updateProperty(e);
              }}
            />
          ),
        },
    tariffSystem === 'XK'
      ? {
          title: `${t('Procedure')} XK`,
          getValue: () => ({
            Extended_customs_procedure:
              item.countrySpecific?.XK?.Extended_customs_procedure,
            National_customs_procedure:
              item.countrySpecific?.XK?.National_customs_procedure,
          }),
          updateProperty: (procedure) => {
            updateItem({
              ...item,
              countrySpecific: {
                XK: {
                  ...(item?.countrySpecific?.XK || {}),
                  ...(procedure || {}),
                },
              },
            });
          },
          renderComponent: (value, updateProperty: (data: any) => void) => (
            <XKProcedureSelect
              value={value}
              onChange={(e) => updateProperty(e)}
            />
          ),
        }
      : {
          title: t('Procedure'),
          getValue: () => item?.customsProcedure,
          updateProperty: (customsProcedure) => {
            updateItem({
              ...item,
              customsProcedure: {
                ...(customsProcedure || {}),
              },
            });
          },
          renderComponent: (value, updateProperty: (data: any) => void) => (
            <ProcedureSelect
              value={value}
              onChange={(e) => updateProperty(e)}
            />
          ),
        },
    {
      title: t('Total Net Weight'),
      getValue: () => item?.totalNetWeight,
      updateProperty: (totalNetWeight) => {
        updateItem({
          ...item,
          totalNetWeight: {
            ...(totalNetWeight || {}),
          },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <WeightInput weight={value} onChange={(e) => updateProperty(e)} />
      ),
    },
    {
      title: t('Total Gross Weight'),
      getValue: () => item?.totalGrossWeight,
      updateProperty: (totalGrossWeight) => {
        updateItem({
          ...item,
          totalGrossWeight: {
            ...(totalGrossWeight || {}),
          },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <WeightInput weight={value} onChange={(e) => updateProperty(e)} />
      ),
    },
    {
      title: t('Quantity'),
      getValue: () => item?.quantity,
      updateProperty: (quantity) => {
        updateItem({
          ...item,
          quantity: { ...(quantity || {}) },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <QuantityInput value={value} onChange={(e) => updateProperty(e)} />
      ),
    },
    {
      title: t('Origin'),
      getValue: () => item?.countryOfOrigin,
      updateProperty: (countryOfOrigin) => {
        updateItem({
          ...item,
          countryOfOrigin: {
            ...(countryOfOrigin || {}),
          },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <CountrySelect value={value} onChange={(e) => updateProperty(e)} />
      ),
    },
    {
      title: t('Total Value'),
      getValue: () => item?.totalValue,
      updateProperty: (totalValue) => {
        updateItem({
          ...item,
          totalValue: { ...(totalValue || {}) },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <AmountOfMoneyInput value={value} onChange={(e) => updateProperty(e)} />
      ),
    },
    {
      title: t('Article Number'),
      getValue: () => item.code,
      updateProperty: (code) => {
        updateItem({ ...item, code });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <Input
          variant="filled"
          title={value}
          value={value}
          placeholder={t('Article Number')}
          onChange={(e) => updateProperty(e.target.value)}
        />
      ),
    },
    {
      title: t('Material Number'),
      getValue: () => item.materialNumber,
      updateProperty: (materialNumber) => {
        updateItem({ ...item, materialNumber });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <Input
          variant="filled"
          title={value}
          value={value}
          placeholder={t('material Number')}
          onChange={(e) => updateProperty(e.target.value)}
        />
      ),
    },
    {
      title: t('Buyer Article Number'),
      getValue: () => item.buyerArticleNumber,
      updateProperty: (buyerArticleNumber) => {
        updateItem({ ...item, buyerArticleNumber });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <Input
          variant="filled"
          title={value}
          value={value}
          placeholder={t('Buyer Article Number')}
          onChange={(e) => updateProperty(e.target.value)}
        />
      ),
    },
    {
      title: t('Reference Number'),
      getValue: () => item.reference,
      updateProperty: (reference) => {
        updateItem({ ...item, reference });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <Input
          variant="filled"
          title={value}
          value={value}
          placeholder={t('Reference Number')}
          onChange={(e) => updateProperty(e.target.value)}
        />
      ),
    },
    {
      title: t('Buyer Order Number'),
      getValue: () => item.buyerOrderNumber,
      updateProperty: (buyerOrderNumber) => {
        updateItem({ ...item, buyerOrderNumber });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <Input
          variant="filled"
          title={value}
          value={value}
          placeholder={t('Buyer Order Number')}
          onChange={(e) => updateProperty(e.target.value)}
        />
      ),
    },
    {
      title: t('Special Unit'),
      getValue: () => item.itemSpecialUnitMeasure,
      updateProperty: ({ itemSpecialUnit, itemSpecialUnitMeasure }) => {
        updateItem({
          ...item,
          ...(itemSpecialUnitMeasure ? { itemSpecialUnitMeasure } : {}),
          ...(itemSpecialUnit ? { itemSpecialUnit } : {}),
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <SpecialMeasureUnit
          itemSpecialUnitMeasure={value.itemSpecialUnitMeasure}
          itemSpecialUnit={item.itemSpecialUnit}
          onChange={(updated) => updateProperty(updated)}
        />
      ),
    },
    {
      title: t('Customs Value'),
      getValue: () => item?.customsValue,
      updateProperty: (customsValue) => {
        updateItem({
          ...item,
          customsValue: { ...(customsValue || {}) },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <AmountOfMoneyInput value={value} onChange={(e) => updateProperty(e)} />
      ),
    },
    {
      title: t('Customs Duty'),
      getValue: () => item?.customsDuty,
      updateProperty: (customsDuty) => {
        updateItem({
          ...item,
          customsDuty: { ...(customsDuty || {}) },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <AmountOfMoneyInput value={value} onChange={(e) => updateProperty(e)} />
      ),
    },
    {
      title: t('Statistical Value'),
      getValue: () => item?.statisticalValue,
      updateProperty: (statisticalValue) => {
        updateItem({
          ...item,
          statisticalValue: {
            ...(statisticalValue || {}),
          },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <AmountOfMoneyInput value={value} onChange={(e) => updateProperty(e)} />
      ),
    },
    {
      title: t('Import Sales Tax'),
      getValue: () => item?.importSalesTax,
      updateProperty: (importSalesTax) => {
        updateItem({
          ...item,
          importSalesTax: {
            ...(importSalesTax || {}),
          },
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <AmountOfMoneyInput value={value} onChange={(e) => updateProperty(e)} />
      ),
    },
    {
      title: t('Freight Cost Distribution'),
      getValue: () => item?.customsValueAdditionDeduction?.[0],
      updateProperty: (deductions) => {
        updateItem({
          ...item,
          customsValueAdditionDeduction: deductions ? [deductions] : [],
        });
      },
      renderComponent: (value, updateProperty: (data: any) => void) => (
        <FreightCostDistributionInput
          value={value}
          onChange={(deductions) => updateProperty(deductions)}
        />
      ),
    },
  ];

  return { detailedView };
};

export const SearchableLineItems = ({
  item,
  updateItem,
  tariffSystem,
}: {
  item: LineItemModel;
  updateItem: (item: LineItemModel) => void;
  tariffSystem: TariffSystem;
}) => {
  const { detailedView } = useLineItemsRenderer({
    item,
    updateItem,
    tariffSystem,
  });

  const [search, setSearch] = useState('');

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: '100%', marginBottom: 24 }}
    >
      <Space size={0} direction="vertical" style={{ width: '100%' }}>
        <Typography.Text>{t('Search Line Item Properties')}</Typography.Text>

        <AutoComplete
          placeholder={t('Search Line Item Fields')}
          value={search}
          size="large"
          onChange={(value) => setSearch(value?.toLowerCase())}
          options={detailedView
            .map((field) => ({
              label: field.title,
              value: field.title,
            }))
            .filter((cur) => cur.label.toLowerCase().includes(search))}
          style={{ width: '100%' }}
          allowClear
        />
      </Space>

      <Space
        direction="vertical"
        style={{ maxHeight: '400px', width: '100%', overflow: 'auto' }}
      >
        {detailedView
          .filter(
            (field) =>
              !!field.getValue() ||
              field.title.toLowerCase().includes(search || 'placeholder'),
          )
          .map((field) => {
            return (
              <Card
                style={{
                  borderColor: field.title
                    .toLowerCase()
                    .includes(search || 'placeholder')
                    ? '#1677ff'
                    : '',
                }}
              >
                <Flex justify="space-between" gap={5}>
                  <Typography.Text style={{ fontWeight: 600 }}>
                    {field.title}
                  </Typography.Text>

                  <div style={{ maxWidth: '70%', minWidth: '40%' }}>
                    {field.renderComponent(
                      field.getValue(),
                      field.updateProperty,
                    )}
                  </div>
                </Flex>
              </Card>
            );
          })}
      </Space>
    </Space>
  );
};
