import { Flex, Select } from 'antd';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { DCTooltip } from '../../../../../common';
import { CaseFieldMapper } from '../../../../CustomsCaseDetails/types';

export const useNormalizationExportMapperES = ({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { t } = useTranslation();

  const fields: CaseFieldMapper[] = [
    {
      title: t('Statistical execStrategy rounding'),
      help: t('Specifies how the statistical execStrategy is rounded.'),
      getValue: () =>
        execStrategy.dataNormalization?.statisticalValueRounding || 'roundOff',
      updateProperty: (statisticalValueRounding) => {
        onValueChange({
          ...execStrategy,
          dataNormalization: {
            ...(execStrategy.dataNormalization || {}),
            statisticalValueRounding,
          },
        });
      },
      renderComponent: (value, updateProperty) => {
        return (
          <Select
            popupMatchSelectWidth={false}
            size="small"
            showSearch
            placeholder={t('Statistical execStrategy rounding')}
            optionFilterProp="children"
            value={value}
            onChange={(statisticalValueRounding) =>
              updateProperty(statisticalValueRounding)
            }
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option value="noRounding">{t('No rounding')}</Select.Option>
            <Select.Option value="roundOff">{t('Round off')}</Select.Option>
            <Select.Option value="roundUp">{t('Round up')}</Select.Option>
            <Select.Option value="roundToNearest">
              {t('Round to nearest')}
            </Select.Option>
          </Select>
        );
      },
    },
  ];

  return { fields };
};

export default function ExportSettings({
  execStrategy,
  onValueChange,
}: {
  execStrategy: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) {
  const { fields } = useNormalizationExportMapperES({
    execStrategy,
    onValueChange,
  });

  return (
    <Flex wrap gap={6}>
      {fields.map((field) => {
        if (field.isCheckbox) {
          return field.renderComponent(
            field.getValue(),
            field.updateProperty,
            field.title,
            field.help as string,
          );
        }

        return (
          <div className="hoverable" style={{ display: 'inline-block' }}>
            {field.title}
            <DCTooltip text={field.help as string} />
            {field.renderComponent(field.getValue(), field.updateProperty)}
          </div>
        );
      })}
    </Flex>
  );
}
