import { Card, Flex, Space, Switch, Typography } from 'antd';
import { DCTooltip } from '../../../common';
import { ReactNode } from 'react';

export const cardBorderCss = {
  margin: 10,
  width: '95%',
  borderLeft: '5px #1677ff solid',
};

export const cardBorderAlternativeCss = {
  // margin: 10,
  // width: '95%',
  // borderLeft: '5px #1677ff solid',
};

export default function CardWrapper({
  title,
  description,
  tooltip,
  checked,
  onChange,
  children,
  alternative,
}: {
  title?: string;
  description?: string;
  tooltip?: string;
  checked?: boolean;
  onChange?: (val: boolean) => void;
  children?: ReactNode;
  alternative?: boolean;
}) {
  return (
    <Card
      bordered
      style={alternative ? cardBorderAlternativeCss : cardBorderCss}
      bodyStyle={{ padding: 16 }}
    >
      <Space direction="vertical" size={0} style={{ marginBottom: 20 }}>
        <Flex align="center" gap={5}>
          {onChange && !checked && (
            <Switch
              size="small"
              checked={checked}
              onChange={(e) => onChange(e)}
            />
          )}
          {title && (
            <>
              <Typography.Title
                level={4}
                style={{ whiteSpace: 'nowrap', margin: 0 }}
              >
                {title}
              </Typography.Title>
              {tooltip && <DCTooltip text={tooltip} />}
            </>
          )}
        </Flex>
        {description && <Typography.Text>{description}</Typography.Text>}
      </Space>
      {children}
    </Card>
  );
}
