import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExecutionStrategy, ProjectModel } from 'digicust_types';

export interface CustomerListItem {
  customerId: string;
  projectId: string;
  name: string;
}

export interface CustomerProjectReduxState {
  customerList: CustomerListItem[];
  projectList: ProjectModel[];
  executionStrategies: ExecutionStrategy[];
}

const customersInitialState: CustomerProjectReduxState = {
  customerList: [],
  projectList: [],
  executionStrategies: [],
};

const customersProjectSlice = createSlice({
  name: 'customers',
  initialState: customersInitialState,
  reducers: {
    setCustomersList: (
      state,
      action: PayloadAction<
        { customerId: string; projectId: string; name: string }[]
      >,
    ) => {
      state.customerList = action.payload;
    },
    setProjectsList: (state, action: PayloadAction<ProjectModel[]>) => {
      state.projectList = action.payload;
    },
    setExecStrategyList: (
      state,
      action: PayloadAction<ExecutionStrategy[]>,
    ) => {
      state.executionStrategies = action.payload;
    },
  },
});

export const { setCustomersList, setProjectsList, setExecStrategyList } =
  customersProjectSlice.actions;
export default customersProjectSlice.reducer;
