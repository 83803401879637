import { Form, Input } from 'antd';
import { DhfSftpConfig } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export default function DHFSystemForm({
  config,
  changeData,
}: {
  config: DhfSftpConfig;
  changeData: (key: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Item label={t('DNS Name')}>
        <Input
          variant="filled"
          placeholder={t('DNS Name')}
          value={config?.['sftp-dns-name']}
          onChange={(e) => changeData('sftp-dns-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Port')}>
        <Input
          variant="filled"
          placeholder={t('Port')}
          value={config?.['sftp-port']}
          onChange={(e) => changeData('sftp-port', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Login name')}>
        <Input
          variant="filled"
          placeholder={t('Login name')}
          value={config?.['sftp-login-name']}
          onChange={(e) => changeData('sftp-login-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Password')}>
        <Input.Password
          variant="filled"
          placeholder={t('Password')}
          value={config?.['sftp-password']}
          onChange={(e) => changeData('sftp-password', e.target.value)}
        />
      </Form.Item>
      <Form.Item label="Path">
        <Input
          variant="filled"
          placeholder="Path"
          value={config?.['sftp-path']}
          onChange={(e) => changeData('sftp-path', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Use Basic FTP')}>
        <Input
          type="checkbox"
          checked={config?.['useBasicFTP']}
          onChange={(e) => changeData('useBasicFTP', e.target.checked)}
        />
      </Form.Item>
    </Form>
  );
}
